/* eslint-disable no-alert */
import Router from 'next/router';
import { put, takeEvery, call } from 'redux-saga/effects';

// Monitoring
import { ClientLogger } from 'src/services/monitoring/clientLogger';

// Utils
import { saveToken, getToken } from '@utils/auth';

// Actions
import { actions as userActions } from '@store/ducks/user';
import { actions as modalActions } from '@store/ducks/modal';

// WiseupCorp API
import { authApi, userApi } from '@services/api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * AUTH
 * ---------------------------------------------------
 */
export function* login({ payload }) {
  try {
    const response = yield call(authApi.login, {
      data: payload.data,
    });

    if (response.status === 200) {
      // yield put(userActions.loginSuccess());
      yield put(
        userActions.loginSuccess({
          userData: response.data.user,
        })
      );
      const loggedUser = {
        name: response?.data?.user?.name,
        surname: response?.data?.user?.surname,
        email: response?.data?.user?.email,
      };
      sessionStorage.setItem('loggedUser', JSON.stringify(loggedUser));

      yield call(saveToken, {
        type: 'client',
        token: response.data.token,
      });
      Router.push('/home');
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        userActions.loginFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors
              ? error.response.data.errors[0].message
              : error.response.data,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * FORGOT PASSWORD
 * ---------------------------------------------------
 */
export function* sendForgotPasswordRequest({ payload }) {
  try {
    const response = yield call(authApi.requestResetPassword, {
      data: payload.data,
    });
    if (response.status === 200) {
      yield put(userActions.forgotPasswordSuccess());
      yield put(
        modalActions.open({
          type: 'RESET_PASSWORD_FEEDBACK',
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    // Security handling
    yield put(userActions.forgotPasswordSuccess());
    yield put(
      modalActions.open({
        type: 'RESET_PASSWORD_FEEDBACK',
      })
    );
  }
}
/**
 * ---------------------------------------------------
 * RESET PASSWORD
 * ---------------------------------------------------
 */
export function* sendResetPasswordRequest({ payload }) {
  try {
    const response = yield call(authApi.setNewPassword, {
      data: {
        newPassword: payload.data.password,
        newPasswordConfirmation: payload.data.passwordConfirmation,
        faToken: payload.data.token,
      },
    });
    if (response.status === 200) {
      yield put(userActions.resetPasswordSuccess());
      yield put(
        modalActions.open({
          type: 'RESET_PASSWORD_SUCCESS',
        })
      );
      yield call(saveToken, {
        type: 'client',
        token: response.data.newToken.token,
      });
      Router.push('/home');
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors
            ? error.response.data.errors[0].message
            : 'Houve um erro ao processar a requisição',
        };
      }
      yield put(userActions.resetPasswordFailure({ toast }));
    }
  }
}
/**
 * ---------------------------------------------------
 * GET USER PROPERTIES BY TOKEN
 * ---------------------------------------------------
 */
export function* getUserPropertiesByTokenRequest({ payload }) {
  try {
    const response = yield call(userApi.sendGetUserByFaToken, {
      token: payload.params,
    });

    if (response.status === 200) {
      yield put(
        userActions.getUserPropertiesByTokenSuccess({
          userData: response.data.user,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors
            ? error.response.data.errors[0].message
            : 'Houve um erro ao processar a requisição',
        };
      }
      yield put(userActions.getUserPropertiesByTokenFailure({ toast }));
    }
  }
}

/**
 * ---------------------------------------------------
 * FIRST ACCESS - GET FIRST ACCESS
 * ---------------------------------------------------
 */
export function* getFirstAccessRequest({ payload }) {
  try {
    const response = yield call(userApi.getFirstAccess, {
      params: payload.params,
    });

    if (response.status === 200) {
      yield put(
        userActions.getFirstAccessSuccess({
          firstAccessData: { ...response.data.user, ...payload.params },
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors[0].message,
        };
      }
      yield put(userActions.getFirstAccessFailure({ toast }));
    }
  }
}

/**
 * ---------------------------------------------------
 * FIRST ACCESS - SEND FIRST ACCESS
 * ---------------------------------------------------
 */

export function* sendFirstAccessRequest({ payload }) {
  try {
    const response = yield call(userApi.sendFirstAccess, {
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(
        userActions.sendFirstAccessSuccess({
          userData: response.data.user,
          firstAccessData: response.data.user,
          toast: {
            type: 'success',
            message: 'Acesso criado com sucesso',
          },
        })
      );
      yield call(saveToken, {
        type: 'client',
        token: response.data.newToken.token,
      });
      Router.push('/home');
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        userActions.sendFirstAccessFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * ADD USER
 * ---------------------------------------------------
 */

export function* sendAddUserRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(userApi.sendAddUser, {
      data: payload.data,
      token,
    });
    if (response.status === 200) {
      yield put(modalActions.close());
      yield put(
        userActions.sendAddUserSuccess({
          toast: {
            type: 'success',
            message: 'Usuário adicionado com sucesso!',
          },
        })
      );
      yield put(userActions.getAllUsersRequest({ token }));
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      if (error?.response?.data?.code === 'USERM_NO_LICENSES') {
        yield put(
          userActions.sendAddUserFailure({
            payload: {},
          })
        );

        yield put(modalActions.close());
        yield put(
          modalActions.open({
            type: 'LICENSE_LIMIT',
          })
        );

        return;
      }

      let errorMessage = null;
      if (error?.response?.data?.code === 'USERM_EMAIL_ALREADY_ACTIVE') {
        errorMessage =
          'Este email encontra-se ativo na base do Wise Up Online. Utilize outro email para seguir com o cadastro.';
      }

      yield put(
        userActions.sendAddUserFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
          payload: { errorMessage },
        })
      );
    }
  }
}
/**
 * ---------------------------------------------------
 * GET ALL USERS
 * ---------------------------------------------------
 */

export function* getAllUsersRequest({ payload }) {
  try {
    let token;

    const search = payload?.search || null;

    if (!payload || !payload.isServer) {
      token = getToken({ type: 'client' });
    } else {
      token = payload.token;
    }
    const response = yield call(userApi.sendGetAllUsers, {
      token,
      search,
    });
    if (response.status === 200) {
      yield put(userActions.getAllUsersSuccess(response.data));
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast = null;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors[0].message,
        };
      }
      yield put(userActions.getAllUsersFailure({ toast }));
    }
  }
}

/**
 * ---------------------------------------------------
 * UPDATE USER
 * ---------------------------------------------------
 */

export function* sendUpdateUserRequest({ payload }) {
  try {
    const token = payload.token ?? getToken({ type: 'client' });
    const response = yield call(userApi.sendUpdateUser, {
      id: payload.data.id,
      data: { ...payload.data },
      token,
    });

    const showToast = payload?.data?.name;
    if (response.status === 200) {
      if (showToast) {
        yield put(modalActions.close());
        yield put(
          userActions.sendUpdateUserSuccess({
            ...payload,
            toast: {
              type: 'success',
              message: 'Usuário editado com sucesso!',
            },
          })
        );
      } else {
        yield put(
          userActions.sendUpdateUserSuccess({
            ...payload,
          })
        );
      }
      yield put(
        userActions.getAllUsersRequest({ token, search: payload?.search })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast = null;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors[0].message,
        };
      }
      yield put(userApi.sendUpdateUserSuccess({ toast }));
    }
  }
}

/**
 * ---------------------------------------------------
 * LOGOUT USER
 * ---------------------------------------------------
 */

export function* sendLogoutUserRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(userApi.sendLogoutUser, {
      token,
    });

    if (response.status === 200) {
      console.log('See ya!');
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors[0].message,
        };
      }
    }
  }
}

/**
 * ---------------------------------------------------
 * VERIFY TOKEN - GET VALIDATION
 * ---------------------------------------------------
 */
export function* getTokenValidationRequest({ payload }) {
  try {
    const response = yield call(userApi.getTokenValidation, {
      params: payload.params,
    });

    if (response.status === 200) {
      yield put(
        userActions.getTokenValidationSuccess({
          isValidRenewPassToken: response.data.valid,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors[0].message,
        };
      }
      yield put(userActions.getTokenValidationFailure({ toast }));
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.LOGIN_REQUEST, login);
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, sendForgotPasswordRequest);
  yield takeEvery(types.GET_FIRST_ACCESS_REQUEST, getFirstAccessRequest);
  yield takeEvery(types.SEND_FIRST_ACCESS_REQUEST, sendFirstAccessRequest);
  yield takeEvery(types.SEND_ADD_USER_REQUEST, sendAddUserRequest);
  yield takeEvery(types.GET_ALL_USERS_REQUEST, getAllUsersRequest);
  yield takeEvery(types.SEND_UPDATE_USER_REQUEST, sendUpdateUserRequest);
  yield takeEvery(types.RESET_PASSWORD_REQUEST, sendResetPasswordRequest);
  yield takeEvery(
    types.GET_USER_PROPERTIES_REQUEST,
    getUserPropertiesByTokenRequest
  );
  yield takeEvery(types.SEND_LOGOUT_USER_REQUEST, sendLogoutUserRequest);
  yield takeEvery(
    types.GET_TOKEN_VALIDATION_REQUEST,
    getTokenValidationRequest
  );
}

export const sagas = [watchUser];
