import { toast } from 'react-toastify';

const ToastMiddleware = () => (next) => (action) => {
  if (action.toast) {
    let config = { hideProgressBar: false };
    
    if (action.toast.type === 'error' || action.toast.type === 'success') config = { ...config, autoClose: 10860 }
    toast[action.toast.type](action.toast.message, {
      ...config,
      ...action.toast.options,
    });
  }
  return next(action);
};

export default ToastMiddleware;
