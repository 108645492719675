import Styled from 'styled-components';

const TablePaginationStyle = Styled.div``;

const TablePaginationTrStyle = Styled.tr`
&:nth-child(1) {
    & td {
      &:first-child {
        &:after {
          display: ${({ isSearch }) => (isSearch ? 'none' : 'initial')};
          content: ${({ checkPageOne, hasTrophy }) => checkPageOne === '1' && hasTrophy ? "url('/assets/icons/goldTrophy.svg')" : ''};
          margin: 0 10px;
        }
      }
    }
  }

  &:nth-child(2) {
    & td {
      &:first-child {
        &:after {
          display: ${({ isSearch }) => (isSearch ? 'none' : 'initial')};
          content: ${({ checkPageOne, hasTrophy }) => checkPageOne === '1' && hasTrophy ? "url('/assets/icons/silverTrophy.svg')" : ''};
          margin: 0 10px;
        }
      }
    }
  }

  &:nth-child(3) {
    & td {
      &:first-child {
        &:after {
          display: ${({ isSearch }) => (isSearch ? 'none' : 'initial')};
          content: ${({ checkPageOne, hasTrophy }) => checkPageOne === '1' && hasTrophy ? "url('/assets/icons/bronzeTrophy.svg')" : ''};
          margin: 0 10px;
        }
      }
    }
  }
`;

export default TablePaginationStyle;
export { TablePaginationStyle, TablePaginationTrStyle };
