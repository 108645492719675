import React from 'react';
import CheckSuccess from '@icons/check-success.svg';
import { Typography } from '@components';
import { Container } from './styles'


const ResetPasswordFeedback = () => {
  return (
    <Container>
      <CheckSuccess />
      <Typography as="span" weight="bold" size="32px">
        Senha alterada<br /> com sucesso
      </Typography>
    </Container>
  );
}

export default ResetPasswordFeedback;
