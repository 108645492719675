/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
import { init as initApm, apm } from '@elastic/apm-rum';
import getConfig from 'next/config';

import { version } from '../../../../../../package.json';

const { publicRuntimeConfig } = getConfig();

export class ElasticAdapter {
  init() {
    initApm({
      serviceName:
        process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME ||
        publicRuntimeConfig.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME,
      serverUrl:
        process.env.REACT_APP_ELASTIC_APM_SERVER_URL ||
        publicRuntimeConfig.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
      environment:
        process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT ||
        publicRuntimeConfig.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT,
      serviceVersion: version,
    });
  }

  captureException(transactionName, severityLevel, error, details) {
    if (details) {
      apm.addLabels({ errorDetails: JSON.stringify(details) });
    }
    // apm.setTransactionName(transactionName);
    apm.captureError(error);
  }

  captureLog(message, severityLevel, details) {
    if (details) {
      apm.addLabels({ errorDetails: JSON.stringify(details) });
    }
    apm.captureError(message);
  }

  captureUIException(error, { componentStack }) {
    apm.addLabels({ componentStack: JSON.stringify(componentStack) });
    apm.captureError(error);
  }

  captureHTTPException(error) {
    apm.addLabels({
      ...(error?.config?.url && {
        requestUrl: error.config.url,
      }),
      ...(error?.response?.status && {
        statusCode: error.response.status,
      }),
      ...(error?.config?.method && {
        requestMethod: error.config.method,
      }),
      ...(error?.response?.data?.error && {
        errorMessage: error.response.data.error,
      }),
      genericErrorMessage: error?.message,
    });
    apm.captureError(error);
  }
}
