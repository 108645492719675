import React from 'react';
import { StyleSwitch } from './Styled';

const Switch = (props) => {
  const { id, name, value, onChange } = props;

  return (
    <StyleSwitch htmlFor={name}>
      <input
        id={id || name}
        name={name || id}
        checked={value}
        onChange={onChange}
        type="checkbox"
      />
      <span />
    </StyleSwitch>
  );
};

Switch.defaultProps = {
  id: '',
  name: '',
  value: false,
  onChange: (e) => e,
};

export default Switch;
export { Switch };
