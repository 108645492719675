/**
 * ---------------------------------------------------
 * AUTH
 * ---------------------------------------------------
 */
export const LOGIN_REQUEST = '@user/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@user/LOGIN_FAILURE';
/**
 * ---------------------------------------------------
 * FORGOT PASSWORD
 * ---------------------------------------------------
 */
export const FORGOT_PASSWORD_REQUEST = '@user/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = '@user/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = '@user/FORGOT_PASSWORD_FAILURE';
/**
 * ---------------------------------------------------
 * RESET PASSWORD
 * ---------------------------------------------------
 */
export const RESET_PASSWORD_REQUEST = '@user/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = '@user/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = '@user/RESET_PASSWORD_FAILURE';

/**
 * ---------------------------------------------------
 * FIRST ACCESS
 * ---------------------------------------------------
 */
export const GET_FIRST_ACCESS_REQUEST = '@user/GET_FIRST_ACCESS_REQUEST';
export const GET_FIRST_ACCESS_SUCCESS = '@user/GET_FIRST_ACCESS_SUCCESS';
export const GET_FIRST_ACCESS_FAILURE = '@user/GET_FIRST_ACCESS_FAILURE';

export const SEND_FIRST_ACCESS_REQUEST = '@user/SEND_FIRST_ACCESS_REQUEST';
export const SEND_FIRST_ACCESS_SUCCESS = '@user/SEND_FIRST_ACCESS_SUCCESS';
export const SEND_FIRST_ACCESS_FAILURE = '@user/SEND_FIRST_ACCESS_FAILURE';

export const GET_USER_PROPERTIES_REQUEST = '@user/GET_USER_PROPERTIES_REQUEST';
export const GET_USER_PROPERTIES_SUCCESS = '@user/GET_USER_PROPERTIES_SUCCESS';
export const GET_USER_PROPERTIES_FAILURE = '@user/GET_USER_PROPERTIES_FAILURE';

/**
 * ---------------------------------------------------
 * ADD USER
 * ---------------------------------------------------
 */
export const SEND_ADD_USER_REQUEST = '@user/SEND_ADD_USER_REQUEST';
export const SEND_ADD_USER_SUCCESS = '@user/SEND_ADD_USER_SUCCESS';
export const SEND_ADD_USER_FAILURE = '@user/SEND_ADD_USER_FAILURE';

/**
 * ---------------------------------------------------
 * GET ALL USERS
 * ---------------------------------------------------
 */
export const GET_ALL_USERS_REQUEST = '@user/GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = '@user/GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = '@user/GET_ALL_USERS_FAILURE';

/**
 * ---------------------------------------------------
 * UPDATE USERS
 * ---------------------------------------------------
 */
export const SEND_UPDATE_USER_REQUEST = '@user/SEND_UPDATE_USER_REQUEST';
export const SEND_UPDATE_USER_SUCCESS = '@user/SEND_UPDATE_USER_SUCCESS';
export const SEND_UPDATE_USER_FAILURE = '@user/SEND_UPDATE_USER_FAILURE';


/**
 * ---------------------------------------------------
 * LOGOUT USER
 * ---------------------------------------------------
 */
export const SEND_LOGOUT_USER_REQUEST = '@user/SEND_LOGOUT_USER_REQUEST';
/**
 * ---------------------------------------------------
 * VERIFY TOKEN
 * ---------------------------------------------------
 */

export const GET_TOKEN_VALIDATION_REQUEST = '@user/GET_TOKEN_VALIDATION_REQUEST';
export const GET_TOKEN_VALIDATION_SUCCESS = '@user/GET_TOKEN_VALIDATION_SUCCESS';
export const GET_TOKEN_VALIDATION_FAILURE = '@user/GET_TOKEN_VALIDATION_FAILURE';
