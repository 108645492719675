import { ElasticAdapter } from './adapters/Elastic';
import { SentryAdapter } from './adapters/Sentry';

class ClientLoggerBase {
  constructor(adapters = [new SentryAdapter(), new ElasticAdapter()]) {
    this.adapters = adapters;
    this.storeWatchers = [];
  }

  init() {
    this.adapters.forEach((logger) => {
      logger.init();
    });
  }

  createStoreWatcher() {
    this.adapters.forEach((logger) => {
      if (typeof logger.createStoreWatcher === 'function') {
        logger.createStoreWatcher();
        this.storeWatchers.push(logger.storeWatchers);
      }
    });
  }

  captureException(transactionName, severityLevel, error, details) {
    this.adapters.forEach((logger) => {
      logger.captureException(transactionName, severityLevel, error, details);
    });
  }

  captureLog(message, severityLevel, details) {
    this.adapters.forEach((logger) => {
      logger.captureLog(message, severityLevel, details);
    });
  }

  captureHTTPException(error) {
    this.adapters.forEach((logger) => {
      logger.captureHTTPException(error);
    });
  }

  captureUIException(error, { componentStack }) {
    this.adapters.forEach((logger) => {
      logger.captureUIException(error, { componentStack });
    });
  }
}

export const ClientLogger = new ClientLoggerBase();
