import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';
import reconciliateStates from '@utils/reconciliateSstates';

const INITIAL_STATE = {
  error: null,
  loading: null,
  initiatedGetAllUsersRequest: false,
  firstAccessData: null,
  users: null,
  userData: null,
  errorMessage: null,
  userDataToken: null,
  isValidRenewPassToken: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return reconciliateStates({
        initialState: INITIAL_STATE,
        currentState: state,
        serverState: action.payload.user,
      });
    /**
     * ---------------------------------------------------
     * AUTH
     * ---------------------------------------------------
     */
    case types.LOGIN_REQUEST:
      return { ...state, loading: true, error: false };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        userData: action.payload.userData,
      };
    case types.LOGIN_FAILURE:
      return { ...state, loading: false, error: true };
    /**
     * ---------------------------------------------------
     * FORGOT PASSWORD
     * ---------------------------------------------------
     */
    case types.FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true, error: false };
    case types.FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.FORGOT_PASSWORD_FAILURE:
      return { ...state, loading: false, error: true };
    /**
     * ---------------------------------------------------
     * RESET PASSWORD
     * ---------------------------------------------------
     */
    case types.RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, error: false };
    case types.RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * FIRST ACCESS - GET FIRST ACCESS
     * ---------------------------------------------------
     */
    case types.GET_FIRST_ACCESS_REQUEST:
      return { ...state, loading: true, error: false };
    case types.GET_FIRST_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        firstAccessData: action.payload.firstAccessData,
      };
    case types.GET_FIRST_ACCESS_FAILURE:
      return { ...state, loading: false, error: true };
    /**
     * ---------------------------------------------------
     * FIRST ACCESS - SEND FIRST ACCESS
     * ---------------------------------------------------
     */
    case types.SEND_FIRST_ACCESS_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_FIRST_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };
    case types.SEND_FIRST_ACCESS_FAILURE:
      return { ...state, loading: false, error: true };
    /**
     * ---------------------------------------------------
     * FIRST ACCESS - GET USER PROPERTIES BY TOKEN
     * ---------------------------------------------------
     */
    case types.GET_USER_PROPERTIES_REQUEST:
      return { ...state, loading: false, error: false };
    case types.GET_USER_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        userData: action.payload.userData,
      };
    case types.GET_USER_PROPERTIES_FAILURE:
      return { ...state, loading: false, error: true };
    /**
     * ---------------------------------------------------
     * ADD USER
     * ---------------------------------------------------
     */
    case types.SEND_ADD_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        ...action.payload,
      };
    case types.SEND_ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.payload?.errorMessage,
      };
    /**
     * ---------------------------------------------------
     * GET ALL USER
     * ---------------------------------------------------
     */
    case types.GET_ALL_USERS_REQUEST:
      return { ...state, loading: true, error: false };
    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        initiatedGetAllUsersRequest: true,
        ...action.payload,
      };
    case types.GET_ALL_USERS_FAILURE:
      return { ...state, loading: false, error: true };
    /**
     * ---------------------------------------------------
     * UPDATE USER
     * ---------------------------------------------------
     */
    case types.SEND_UPDATE_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case types.SEND_UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * VALIDATION TOKEN
     * ---------------------------------------------------
     */
    case types.GET_TOKEN_VALIDATION_REQUEST:
      return { ...state, loading: true, error: false };
    case types.GET_TOKEN_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };
    case types.GET_TOKEN_VALIDATION_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * DEFAULT -------------------------------------------
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
