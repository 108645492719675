import * as types from './types';

/**
 * ---------------------------------------------------
 * AUTH
 * ---------------------------------------------------
 */
export const loginRequest = (payload) => ({
  type: types.LOGIN_REQUEST,
  payload,
});
export const loginSuccess = (payload) => ({
  type: types.LOGIN_SUCCESS,
  payload,
});
export const loginFailure = ({ toast }) => ({
  type: types.LOGIN_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * FORGOT PASSWORD
 * ---------------------------------------------------
 */
export const forgotPasswordRequest = (payload) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordSuccess = (payload) => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailure = ({ toast }) => ({
  type: types.FORGOT_PASSWORD_FAILURE,
  toast,
});
/**
 * ---------------------------------------------------
 * RESET PASSWORD
 * ---------------------------------------------------
 */
export const resetPasswordRequest = (payload) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: types.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailure = ({ toast }) => ({
  type: types.RESET_PASSWORD_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * FIRST ACCESS
 * ---------------------------------------------------
 */

export const getFirstAccessRequest = (payload) => ({
  type: types.GET_FIRST_ACCESS_REQUEST,
  payload,
});
export const getFirstAccessSuccess = (payload) => ({
  type: types.GET_FIRST_ACCESS_SUCCESS,
  payload,
});
export const getFirstAccessFailure = ({ toast }) => ({
  type: types.GET_FIRST_ACCESS_FAILURE,
  toast,
});

export const sendFirstAccessRequest = (payload) => ({
  type: types.SEND_FIRST_ACCESS_REQUEST,
  payload,
});
export const sendFirstAccessSuccess = (payload) => ({
  type: types.SEND_FIRST_ACCESS_SUCCESS,
  payload,
});
export const sendFirstAccessFailure = ({ toast }) => ({
  type: types.SEND_FIRST_ACCESS_FAILURE,
  toast,
});

export const getUserPropertiesByToken = (payload) => ({
  type: types.GET_USER_PROPERTIES_REQUEST,
  payload,
});
export const getUserPropertiesByTokenSuccess = (payload) => ({
  type: types.GET_USER_PROPERTIES_SUCCESS,
  payload,
});
export const getUserPropertiesByTokenFailure = ({ toast }) => ({
  type: types.GET_USER_PROPERTIES_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * ADD USER
 * ---------------------------------------------------
 */

export const sendAddUserRequest = (payload) => ({
  type: types.SEND_ADD_USER_REQUEST,
  payload,
});
export const sendAddUserFailure = ({ toast, payload }) => {
  return {
    type: types.SEND_ADD_USER_FAILURE,
    toast,
    payload,
  };
};
export const sendAddUserSuccess = ({ toast, ...payload }) => ({
  type: types.SEND_ADD_USER_SUCCESS,
  payload,
  toast,
});

/**
 * ---------------------------------------------------
 * GET ALL USERS
 * ---------------------------------------------------
 */

export const getAllUsersRequest = (payload) => ({
  type: types.GET_ALL_USERS_REQUEST,
  payload,
});
export const getAllUsersFailure = (payload) => ({
  type: types.GET_ALL_USERS_FAILURE,
  payload,
});
export const getAllUsersSuccess = (payload) => ({
  type: types.GET_ALL_USERS_SUCCESS,
  payload,
});

/**
 * ---------------------------------------------------
 * UPDATE USER
 * ---------------------------------------------------
 */

export const sendUpdateUserRequest = (payload) => ({
  type: types.SEND_UPDATE_USER_REQUEST,
  payload,
});
export const sendUpdateUserFailure = ({ toast }) => ({
  type: types.SEND_UPDATE_USER_FAILURE,
  toast,
});
export const sendUpdateUserSuccess = ({ toast, ...payload }) => ({
  type: types.SEND_UPDATE_USER_SUCCESS,
  payload,
  toast,
});

export const sendLogoutUserRequest = (payload) => ({
  type: types.SEND_LOGOUT_USER_REQUEST,
  payload,
});
/**
 * ---------------------------------------------------
 * VERIFY TOKEN
 * ---------------------------------------------------
 */
export const getTokenValidationRequest = (payload) => ({
  type: types.GET_TOKEN_VALIDATION_REQUEST,
  payload,
});
export const getTokenValidationSuccess = (payload) => ({
  type: types.GET_TOKEN_VALIDATION_SUCCESS,
  payload,
});
export const getTokenValidationFailure = ({ toast }) => ({
  type: types.GET_TOKEN_VALIDATION_FAILURE,
  toast,
});
