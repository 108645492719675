/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useCallback } from 'react';

import { validateEmail, validateNameComplete } from '@utils/inputValid';

// Utils
import { getGroups } from '@utils';

import { useSelector, useDispatch } from 'react-redux';

import { InputBase as Input, Button, Typography, Select } from '@components';

// Form & Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';
import { actions as groupActions } from '@store/ducks/group';

// Styles
import PencilIcon from '@icons/pencil.svg';
import {
  TitleModal,
  Wrapper,
  InputRow,
  ButtonWrapper,
  EditButton,
} from './styles';

const ModalEditUser = ({ props }) => {
  const { user, search } = props;

  const {
    id,
    email,
    name,
    employee_registry,
    phone,
    position,
    group,
    status,
    user_activated,
  } = user;

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const { groups } = useSelector((state) => state.group);
  const [selectGroup, setSelectGroup] = useState([]);
  const [isLocked, setIsLocked] = useState(true);

  useEffect(() => {
    dispatch(groupActions.getAllGroupsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (groups.length) {
      setSelectGroup(getGroups(groups));
    }
  }, [groups]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name || '',
      employee_registry: employee_registry || '',
      phone: phone || '',
      email: email || '',
      position: position || '',
      group: group || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .test({
          message: 'Revise seu nome e sobrenome',
          test: (fullName) => validateNameComplete(fullName),
        })
        .min(2, 'No mínimo 2 caracteres.')
        .max(85, 'No máximo 85 caracteres.')
        .required('O nome e sobrenome é obrigatório.'),

      email: Yup.string()
        .min(7, 'Um email precisa de no mínimo 7 caracteres.')
        .max(64, 'O Endereço de email esta muito grande.')
        .test({
          message: 'O email é inválido',
          test: (mail) => validateEmail(mail),
        })
        .lowercase(),
      employee_registry: Yup.string().max(15, 'No máximo 15 caracteres.'),
      position: Yup.string().max(85, 'No máximo 85 caracteres.'),
      phone: Yup.string()
        .required('O celular é obrigatório')
        .min(15, 'Digite o número completo.'),
      group: Yup.string().required('O grupo é obrigatório.'),
    }),

    onSubmit: async (values) => {
      const data = {
        id,
        name: values.name,
        employee_registry: values.employee_registry,
        phone: values.phone,
        position: values.position,
        group: values.group,
        status,
        email: values.email,
      };
      if (id) dispatch(userActions.sendUpdateUserRequest({ data, search }));
    },
  });

  const handleReset = useCallback(() => {
    setIsLocked(true);

    formik.setFieldValue('name', name);
    formik.setFieldValue('employee_registry', employee_registry);
    formik.setFieldValue('phone', phone);
    formik.setFieldValue('email', email);
    formik.setFieldValue('position', position);
    formik.setFieldValue('group', group);

    document.getElementsByName('name')[0].value = name;
    document.getElementsByName(
      'employee_registry'
    )[0].value = employee_registry;
    document.getElementsByName('phone')[0].value = phone;
    document.getElementsByName('email')[0].value = email;
    document.getElementsByName('position')[0].value = position;
    document.getElementsByName('group')[0].value = group;

    formik.resetForm();
  }, [formik, employee_registry, group, name, email, phone, position]);

  const messageTooltip =
    'Não é possível editar este campo, entre em contato com nosso suporte para ajustes.';

  return (
    <>
      <TitleModal>
        <Typography as="h1" weight="bold" size="32px" className="title">
          {formik.values.name}
        </Typography>

        {isLocked && (
          <EditButton onClick={() => setIsLocked(false)}>
            <span>Editar</span>
            <PencilIcon />
          </EditButton>
        )}
      </TitleModal>

      <form onSubmit={formik.handleSubmit}>
        <Wrapper>
          <Input
            name="name"
            tooltipMessage={messageTooltip}
            value={isLocked ? name : formik.values.name}
            placeholder="Nome completo do colaborador"
            // onChange={formik.handleChange}
            label="Nome Completo"
            status={
              formik.errors.name && formik.touched.name
                ? formik.errors.name
                : null
            }
            disabled
          />

          <InputRow>
            <Input
              name="email"
              label="E-mail Corporativo"
              placeholder="nome.sobrenome@empresa.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              tooltipMessage={
                user_activated
                  ? messageTooltip
                  : 'Este campo não poderá ser editado depois que o usuário fizer o primeiro acesso no Wise Up Online. Caso necessite de ajustes neste campo depois do primeiro acesso do usuário, entre em contato com nosso suporte.'
              }
              status={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null
              }
              disabled={user_activated || !!isLocked}
            />

            <Input
              name="phone"
              tooltipMessage={messageTooltip}
              mask="phone"
              label="Celular"
              placeholder="(11) 9 87654-3210"
              value={isLocked ? phone : formik.values.phone}
              // onChange={formik.handleChange}
              status={
                formik.errors.phone && formik.touched.phone
                  ? formik.errors.phone
                  : null
              }
              disabled
            />
          </InputRow>

          <InputRow>
            <Input
              name="employee_registry"
              label="Matrícula"
              placeholder="1234-5678"
              value={
                isLocked ? employee_registry : formik.values.employee_registry
              }
              onChange={({ target }) =>
                formik.setFieldValue('employee_registry', target.value)
              }
              status={
                formik.errors.employee_registry &&
                  formik.touched.employee_registry
                  ? formik.errors.employee_registry
                  : null
              }
              disabled={!!isLocked}
            />

            <Input
              name="position"
              label="Função"
              placeholder="Gerente comercial"
              value={isLocked ? position : formik.values.position}
              onChange={formik.handleChange}
              status={
                formik.errors.position && formik.touched.position
                  ? formik.errors.position
                  : null
              }
              disabled={!!isLocked}
            />
          </InputRow>

          <Select
            name="group"
            label="Grupo"
            placeholder="Business"
            value={isLocked ? group : formik.values.group}
            onChange={(e) => formik.setFieldValue('group', e.target.value)}
            options={selectGroup}
            refProp="title"
            variant="inside-label"
            status={
              formik.errors.group && formik.touched.group
                ? formik.errors.group
                : null
            }
            disabled={!!isLocked}
          />
          {!isLocked && (
            <ButtonWrapper>
              <Button type="button" variant="secondary" onClick={handleReset}>
                Cancelar
              </Button>

              <Button type="submit" variant="primary" loading={loading} block>
                Salvar
              </Button>
            </ButtonWrapper>
          )}
        </Wrapper>
      </form>
    </>
  );
};

export default ModalEditUser;
