import color from './color';

const typography = {
  fontFamily:
    '"Avenir", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  baseSize: '16px',
  textAlign: 'left',
  fontWeight: {
    light: '200',
    medium: '400',
    bold: '600',
    black: '800',
    default: '400',
  },
  fontSize: {
    default: '.9rem',
    base: '16px',
    xs: '.5rem',
    sm: '.7rem',
    md: '1rem',
    lg: '3.1rem',
    xl: '5rem',
  },
  paragraph: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '.9rem',
    color: color.grey10,
    lineHeight: 'normal',
    letterSpacing: '-.02em',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '3.1rem',
    color: color.grey10,
    lineHeight: '3.1rem',
  },
};

export default typography;
export { typography };
