import Styled from 'styled-components';

const SidebarStyle = Styled.aside`
	width: 240px;
	height: auto;
	min-height: 100vh;
	overflow-y: auto;
	padding: 25px 0;
	border-right: 1px solid #E5E5E5;


	& ul {
		min-width: 100%;

		& li {
			cursor: pointer;
			line-height: 45px;
			height: 65px;
			position: relative;
			transition: background-color .3s ease-out;
			
			&:hover {
				background: #F7F7F7;
			}

			&.active {
				font-weight: 800;
				
				& svg {
				> path, 
					> rect {
						fill: #00EB84 !important;
					}
				}
				
				a .box-menu-icon-title span{
					font-weight: 800;
					color: #180A29;
				}
				
				.line-active-menu{
					display: block;
				}
			}

			&.disable {

				&:before {
					content: '';
					width: 100%;
					height: 100%;
					background-color: transparent;
					position: absolute;
				}

				> span {
					position: absolute;
					top: 23px;
    				right: 13px;
					background: #8E8E93;
					color: #fff;
					font-size: .6rem;
					padding: 2px 5px;
					border-radius: 5px;					
				}
			}

			> a {
				display: block;
				font-size: 15px;
				color: #000;
				padding-left: 32px;
				min-width: 100%;
				min-height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.box-menu-icon-title {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				
				.box-menu-icon-title span {
					padding-left: 25px;
					color: #8C8C8C;
				}

				.line-active-menu{
					display: none;
					width: 6px;
					height: 65px;
					background: #00EB84;
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
					border-radius: 8px 0px 0px 8px;
				}
			}
		}
	}
`;

export default SidebarStyle;
export { SidebarStyle };
