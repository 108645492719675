import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@components';
import CheckSuccess from '@icons/check-success.svg';
import { Wrapper, IconContainer } from './styles';

const InactivateUserSuccess = ({ props }) => {
  const title = props.status ? 'inativado' : 'ativado';
  return (
    <Wrapper>
      <IconContainer>
        <CheckSuccess />
      </IconContainer>

      <Typography
        align="center"
        color="#231F20"
        as="h1"
        dsize="32px"
        className="title"
        weight="600"
        dheight="37px"
      >
        {`Usuário ${title} com sucesso`}
      </Typography>
    </Wrapper>
  );
};

InactivateUserSuccess.defaultProps = {
  props: {
    status: '',
  },
  status: '',
};

InactivateUserSuccess.propTypes = {
  props: PropTypes.shape({
    status: PropTypes.string,
  }),
  status: PropTypes.string,
};

export default InactivateUserSuccess;
