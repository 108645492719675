import React, { useState, useEffect } from 'react';

// LIBS
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// UTILS
import { arrGroups, getGroups } from '@utils';

// STYLE
import { MenuDropDown, Checkbox } from '@components';
import { TooltipStyle } from '../Styled';

// COMPONENTS

const FilterGroups = (props) => {
  const { filter, getFilterGroups, fullChecked, getType } = props;
  const [groupsList, setGroupsList] = useState([]);
  const [fullCheck, setFullCheck] = useState(false);
  const { group } = useSelector((state) => state);

  const getGroupsSelects = () =>
    groupsList.filter((group) => group.selected === true);

  const getSelected = () => {
    const selects = getGroupsSelects();
    switch (getType) {
      case 'string':
        const arrGroupLabel = selects.map((group) => group.label);
        const strSeparatedComma = arrGroupLabel.join();
        getFilterGroups(strSeparatedComma);
        break;
      case 'array':
        const groupLabelArr = selects.map((group) => group.label);
        getFilterGroups(groupLabelArr);
        break;
      case 'object':
        getFilterGroups(selects);
        break;
      default:
        console.error('FilterGroups: getSelected key invalid');
        break;
    }
  };

  const handleFullToggle = ({ arrayGroups = groupsList }) => {
    const allToggleFilter = [...arrayGroups];
    allToggleFilter?.forEach((obj) => (obj.selected = !fullCheck));
    setFullCheck(!fullCheck);
    setGroupsList(allToggleFilter);
  };

  const handleSelect = (label) => {
    const toggleFilter = [...groupsList];
    toggleFilter.forEach((obj) => {
      if (obj.label === label) obj.selected = !obj.selected;
    });

    if (
      groupsList.every(
        (item) => item.label != 'Todos' && item.selected === true
      )
    ) {
      setFullCheck(true);
    } else setFullCheck(false);

    setGroupsList(toggleFilter);
  };

  const renderOptions = groupsList.map((option, index) => (
    <li key={index}>
      <Checkbox
        name={`${filter}-${option.label}`}
        label={option.label}
        checked={option.selected}
        onChange={() => handleSelect(option.label)}
      />
    </li>
  ));

  const renderAutoCheck = (
    <li>
      <Checkbox
        name={`${filter}-todos`}
        label="Todos"
        checked={fullCheck}
        onChange={handleFullToggle}
      />
    </li>
  );

  useEffect(() => {
    if (getFilterGroups) getSelected();
  }, [groupsList]);

  useEffect(() => {
    const { groups } = group;
    if (groups?.length) {
      let arrayGroups = arrGroups(groups);
      if (fullChecked) {
        arrayGroups.forEach((item) => (item.selected = true));
        setFullCheck(true);
        return setGroupsList(arrayGroups);
      }
      return setGroupsList(arrayGroups);
    }
  }, [group]);

  return (
    <>
      <TooltipStyle>
        <MenuDropDown top="50px" label="Filtro de Grupos">
          <span>Especifique um grupo</span>
          <ul>
            {renderAutoCheck}
            {groupsList.length ? renderOptions : false}
          </ul>
        </MenuDropDown>
      </TooltipStyle>
    </>
  );
};

FilterGroups.defaultProps = {
  filter: '',
  fullChecked: true,
  getType: 'string',
};

FilterGroups.prototypes = {
  filter: PropTypes.oneOf([
    'graphicStudyHours',
    'graphicFrequency',
    'graphicCompletedSteps',
    'ranking',
  ]),
  getType: PropTypes.oneOf(['string', 'array', 'object']),
  getFilterGroups: PropTypes.func,
};

export default FilterGroups;
export { FilterGroups };
