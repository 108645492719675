import { callApiBase } from './base';

const dashboardApi = {
  // DASHBOARD GRAPHIC - STUDY HOURS
  getGraphicStudyHours: ({ token, params }) =>
    callApiBase({
      title: 'Dashboard - Graphic - Study Hours',
      endpoint: '/v1/graphic/sessions',
      method: 'GET',
      token,
      params,
    }),
  // DASHBOARD GRAPHIC - ACCESS FREQUENCY
  getGraphicAccessFrequency: ({ token, params }) =>
    callApiBase({
      title: 'Dashboard - Graphic - Access Frequency',
      endpoint: '/v1/graphic/accesses',
      method: 'GET',
      token,
      params,
    }),
  // DASHBOARD GRAPHIC - COMPLETED STEPS
  getGraphicCompletedSteps: ({ token, params }) =>
    callApiBase({
      title: 'Dashboard - Graphic - Completed Steps',
      endpoint: '/v1/graphic/steps',
      method: 'GET',
      token,
      params,
    }),
    // DASHBOARD - ACHIEVEMENT
  getDashAchievement: ({ token }) =>
    callApiBase({
      title: 'Dashboard - Achievement',
      endpoint: '/v1/dash/ranking',
      method: 'GET',
      token,
    }),
    // DASHBOARD - STUDY_HOURS
  getDashStudyHours: ({ token, params }) =>
    callApiBase({
      title: 'Dashboard - Study Hours',
      endpoint: '/v1/dash/sessions',
      method: 'GET',
      token,
      params,
    }),
  // DASHBOARD - MOST_ACCESSED_CONTENT
  getDashMostAccessedContent: ({ token }) =>
    callApiBase({
      title: 'Dashboard - Most Accessed Content',
      endpoint: '/v1/dash/most-accessed-content',
      method: 'GET',
      token,
    }),
  // DASHBOARD - WIDGETS
  getDashWidgets: ({ token }) => 
    callApiBase({
      title: 'Dashboard - Widgets',
      endpoint: '/v1/dash/widgets',
      method: 'GET',
      token,
    }),
};

export default dashboardApi;
export { dashboardApi };
