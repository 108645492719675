/* eslint-disable no-alert */
import { put, takeEvery, call } from 'redux-saga/effects';

// Monitoring
import { ClientLogger } from 'src/services/monitoring/clientLogger';

// Utils
import { getToken } from '@utils/auth';

// Actions
// eslint-disable-next-line import/no-cycle
import { actions as groupActions } from '@store/ducks/group';
import { actions as modalActions } from '@store/ducks/modal';

// WiseupCorp API
import { groupApi } from '@services/api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * ADD GROUP
 * ---------------------------------------------------
 */

export function* sendAddGroupRequest({ payload }) {
  try {
    const token = payload.token ?? getToken({ type: 'client' });
    const response = yield call(groupApi.sendAddGroup, {
      data: { json: payload.data },
      token,
    });
    if (response.status === 200) {
      yield put(modalActions.close());

      yield put(groupActions.getAllGroupsRequest({ token }));
      if (payload?.showToast) {
        yield put(
          groupActions.sendAddGroupSuccess({
            toast: {
              type: 'success',
              message: 'Grupo adicionado com sucesso!',
            },
          })
        );

        return;
      }
      yield put(groupActions.sendAddGroupSuccess({}));
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        groupActions.sendAddGroupFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * GET ALL GROUP
 * ---------------------------------------------------
 */

export function* getAllGroupsRequest({ payload }) {
  try {
    let token;
    if (!payload || !payload.isServer) {
      token = getToken({ type: 'client' });
    } else {
      token = payload.token;
    }
    const response = yield call(groupApi.sendGetAllGroup, {
      token,
    });
    if (response.status === 200) {
      yield put(
        groupActions.getAllGroupsSuccess({
          ...response.data,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        groupActions.getAllGroupsFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

export function* watchGroup() {
  yield takeEvery(types.SEND_ADD_GROUP_REQUEST, sendAddGroupRequest);
  yield takeEvery(types.GET_ALL_GROUPS_REQUEST, getAllGroupsRequest);
}

export const sagas = [watchGroup];
