import Styled from 'styled-components';

const MarginStyle = Styled.div`
  margin-left: ${({ theme }) => theme.left}px; 
  margin-top: ${({ theme }) => theme.top}px; 
  margin-right: ${({ theme }) => theme.right}px; 
  margin-bottom: ${({ theme }) => theme.bottom}px; 
  display: flex;
`;

export default MarginStyle;
export { MarginStyle };
