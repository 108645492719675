import { callApiBase } from './base';

const authApi = {
  login: ({ data }) =>
    callApiBase({
      title: 'Auth - Login',
      endpoint: '/v1/login',
      method: 'POST',
      data,
    }),
  requestResetPassword: ({ data }) =>
    callApiBase({
      title: 'Auth - Request Password Reset',
      endpoint: '/v1/user/reset-password',
      method: 'POST',
      data,
    }),
  setNewPassword: ({ data }) =>
    callApiBase({
      title: 'Auth - Set new Password',
      endpoint: '/v1/user/new-password',
      method: 'POST',
      data,
    }),
};

export default authApi;
export { authApi };
