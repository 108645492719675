import Styled from 'styled-components';
import * as token from '@theme';

const InputNumber = Styled.div`
	border: 2px solid #F0F0F0;
	position: relative;
	flex-grow: 0;
	margin-left: 10px;
	border-radius: 3px;

	&:after,
	&:before {
    content: '';
    position: absolute;
    left: -5px;
    top: calc(50% - 5px);
    background: transparent;
    border-right: 5px solid white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    width: 0;
    height: 0;
	}

	&:before {
		left: -8px;
    top: calc(50% - 7px);
    border-right: 7px solid #E5E5E5;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
	}

	& input {
		z-index: 100;
		border-bottom: none !important;
		border: none !important;
		background: transparent;
		padding: 5px;
    padding-right: 0;
		font-weight: bold;
    font-size: 14px;
    line-height: 14px;
		width: 100%;
		box-sizing: border-box;

		&:focus,
		&:hover,
		&:active {
			border: none !important;
			outline: none !important;
		}
	}
`;

const InputSlider = Styled.input`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-appearance: none;
	width: calc(100% - 15%);
	height: 2px;
	background: #F0F0F0;
	outline: none;
	padding: 0;
	margin: 0 auto;
	position: relative;
	border-radius: 14px;

	&::after {
		position: absolute;
		content: '';
		left: 0;
		top: -.5px;
		bottom: 0;
		width: ${({ theme }) => `calc(${theme.width}% / 2)` || '50%'};
		height: 3px;
		border-radius: 14px;
		background: ${token.color.primary};
	}

	&::-webkit-progress-bar {
		background: ${token.color.primary};

	}
	&::-webkit-progress-value {
		background: ${token.color.primary};
	}

	&::-webkit-slider-progress {
		background: ${token.color.primary};
	}

	&::-webkit-slider-thumb {
		appearance: none;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: ${token.color.primary};
		border: 1px solid rgba(120,120,128,0.32);
		box-shadow: 0px 3px 8px rgba(0,0,0,0.15),
		opacity: 1;
		cursor: pointer;
		transition: all .2s ease-in-out;

		&:hover {
			width: 21px;
			height: 21px;
		}
	}

	&::-moz-range-progress {
		background: ${token.color.primary};
	}

	&::-moz-range-thumb {
		width: 18px;
		height: 18px;
		border: 0;
		border-radius: 50%;
		background: ${token.color.primary};
		cursor: pointer;
		transition: all .2s ease-in-out;

		&:hover {
			width: 21px;
			height: 21px;
		}
	}
/* } */
`;

const RangeSliderStyle = Styled.div`
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	> :first-child{
		flex-grow: 1;
		max-width: 80%;
	}
	> :last-child{
		flex-grow: 0;
		max-width: 20%;
	}
`;

export default { RangeSliderStyle };
export { RangeSliderStyle, InputNumber, InputSlider };
