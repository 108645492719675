import React from 'react';
import PropTypes from 'prop-types';
import ChevronDown from '@icons/chevron-down.svg';

// STYLE
import { InsideLabel, OutsideLabel, StyleError, StyledChevron } from './Styled';

const Select = (props) => {
  const {
    id,
    label,
    name,
    status,
    type,
    value,
    placeholder,
    required,
    onChange,
    disabled,
    variant,
    options,
  } = props;

  const idName = name || label.replace(/ /g, '-').toLowerCase();

  const elementSelect = (
    <>
      <select
        id={idName}
        name={idName}
        type={type}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      >
        <option value="">Selecione</option>
        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <StyledChevron>
        <ChevronDown />
      </StyledChevron>
      {status ? <StyleError>{status}</StyleError> : false}
    </>
  );

  const insideBox = (
    <InsideLabel htmlFor={idName}>
      <label htmlFor={idName}>{label}</label>
      <div>{elementSelect}</div>
    </InsideLabel>
  );

  const outside = (
    <OutsideLabel>
      <label htmlFor={idName}>{label}</label>
      {elementSelect}
    </OutsideLabel>
  );

  if (variant === 'outside-label') return outside;
  return insideBox;
};

Select.defaultProps = {
  name: '',
  label: '',
  variant: 'outside-label',
  status: false,
  disabled: false,
  type: 'text',
  value: '',
  placeholder: '',
  required: false,
  onChange: () => null,
};

Select.prototypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['outside-label', 'inside-label']),
  status: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'date']),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Select;
export { Select };
