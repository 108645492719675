import Styled from 'styled-components';

const StyleModal = Styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: all .8s linear;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(30 30 30 / 55%);
    transition: all .8s linear;
  }

  > div {
    width: auto;
    display: flex;
    position: sticky;
    justify-content: center;
    align-items: center;
    height: 100%;

    > .modal-content {
      display: flex;
      position: relative;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      width: calc(100% - 30px);
      margin: 0 auto;
      max-width: 600px;

      &.smaller-box {
        max-width: 464px;
      }

      > .modal-body {
        padding: 2rem;
        margin: auto;
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        text-align: left;
      }
    }
  }

  &.show {
    opacity: 1;
    display: block;
    transition: all .8s linear;
  }
`;

const Close = Styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;

  > button {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
  }
`;

export default StyleModal;
export { StyleModal, Close };
