function objPrototype(value, label) {
  this.value = value;
  this.label = label || value;
}

function buildHierarchyGroup(arr) {
  const arrOutput = [];
  let temp = [];

  const handleResetTemp = () => (temp = []);
  const handleGroupsInline = () => (temp = temp.join(' > '));
  const handlePush = (obj) => arrOutput.push(obj);
  const handlePushTemp = (str) => temp.push(str);

  const build = (array) => {
    array.reduce((flat, toFlatten) => {
      const { children, title } = toFlatten;

      if (children && children?.length) {
        handlePushTemp(title);
        build(children);
      } else if (temp.length) {
        handlePushTemp(title);
        handleGroupsInline();
        const objBuild = new objPrototype(temp);
        handlePush(objBuild);
        handleResetTemp();
      } else {
        const objBuild = new objPrototype(title);
        handlePush(objBuild);
      }
    }, []);

    return arrOutput;
  };

  return build(arr);
}

export default buildHierarchyGroup;
export { buildHierarchyGroup };
