import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Typography } from '@components';
import { actions as modalActions } from '@store/ducks/modal';
import { actions as userActions } from '@store/ducks/user';
import { Wrapper, SubTitle, ButtonContainer } from './styles';

const InactivateUser = ({ props }) => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.user);
  const closeModal = () => dispatch(modalActions.close());
  const { id, status } = props;
  const [showNextModal, setShowNextModal] = useState();
  const messages = {
    title: status ? 'inativar' : 'ativar',
    subTitle: status
      ? `O usuário perderá acesso imediatamente à plataforma, só podendo acessar
      novamento se você reativá-lo.`
      : 'O usuário receberá acesso novamente à plataforma.',
  };

  useEffect(() => {
    if (!error && !loading && showNextModal) {
      dispatch(modalActions.close());
      dispatch(
        modalActions.open({
          type: 'INACTIVATE_USER_SUCCESS',
          props: { status },
        })
      );
    }
  }, [loading, error, dispatch, status, showNextModal]);

  const openSuccessModal = () => {
    setShowNextModal(true);
    dispatch(
      userActions.sendUpdateUserRequest({
        data: {
          id,
          status: !status,
        },
      })
    );
  };

  return (
    <Wrapper>
      <Typography
        align="center"
        color="#231F20"
        as="h1"
        dsize="32px"
        className="title"
        weight="600"
        dheight="37px"
      >
        {`Você gostaria de ${messages.title} o usuário?`}
      </Typography>

      <SubTitle>{messages.subTitle}</SubTitle>

      <ButtonContainer>
        <Button variant="secondary" onClick={closeModal}>
          Não
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          onClick={openSuccessModal}
          variant="primary"
        >
          Sim
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

InactivateUser.defaultProps = {
  props: {
    id: '',
    status: '',
  },
  id: '',
  status: '',
};

InactivateUser.propTypes = {
  props: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.string,
  }),
  id: PropTypes.string,
  status: PropTypes.string,
};

export default InactivateUser;
