/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Brand } from '../Brand';
import { UserMenu } from './UserMenu';
import { StyleHeader } from './Styled';

const Header = ({ menuUser }) => (
  <StyleHeader justifyContent={menuUser ? 'space-between' : 'center'}>
    <Brand menuUser={menuUser} />
    {menuUser ? <UserMenu /> : false}
  </StyleHeader>
);

Header.propTypes = {
  menuUser: PropTypes.bool,
};

Header.defaultProps = {
  menuUser: true,
};

export default Header;
export { Header };
