import React from 'react';
import PropTypes from 'prop-types';

// Style
import { TableStyle } from './Styled';

const Table = ({ children }) => <TableStyle>{children}</TableStyle>;

Table.prototypes = {
  children: PropTypes.element.isRequired,
};

export default Table;
export { Table };
