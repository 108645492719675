/* eslint-disable no-alert */
import { put, takeEvery, call } from 'redux-saga/effects';

// Monitoring
import { ClientLogger } from 'src/services/monitoring/clientLogger';

// Actions
// eslint-disable-next-line import/no-cycle
import { actions as planActions } from '@store/ducks/plan';

// WiseupCorp API
import { planApi } from '@services/api';

// Utils
import { getToken } from '@utils/auth';

import * as types from './types';

/**
 * ---------------------------------------------------
 * PLAN
 * ---------------------------------------------------
 */
export function* getPlan({ payload }) {
  try {
    let token;
    if (!payload || !payload.isServer) {
      token = getToken({ type: 'client' });
    } else {
      token = payload.token;
    }

    const response = yield call(planApi.getPlan, {
      token,
    });

    if (response.status === 200) {
      yield put(planActions.planSuccess(response.data));
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      let toast = null;
      if (!payload.isServer) {
        toast = {
          type: 'error',
          message: error.response.data.errors[0].message,
        };
      }
      yield put(planActions.planFailure({ toast }));
    }
  }
}

export function* watchPlan() {
  yield takeEvery(types.PLAN_REQUEST, getPlan);
}

export const sagas = [watchPlan];
