/* eslint-disable @typescript-eslint/no-explicit-any */
import { diff } from 'jsondiffpatch';

export default function reconciliateStates({
    initialState,
    currentState,
    serverState
}) {
    const nextState = {
        ...currentState,
        ...serverState
    }
    const clientDelta = diff(currentState, initialState) ?? {}
    const serverDelta = diff(serverState, initialState) ?? {}

    const states = Object.keys(initialState)
    for (const s of states) {
        // Keep client state if current state differs from initial state and server state is the same as the initial state
        if (
            Object.keys(clientDelta).includes(s) &&
            !Object.keys(serverDelta).includes(s)
        ) {
            // console.log(`Keeping ${s} value from client state...`)
            nextState[s] = currentState[s]
        }
    }
    return nextState
}
