import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { StyleBrand } from './Styled';

// Component Icon
import Logotype from '@public/assets/logotype/wiseup-corporate.svg';

const Brand = ({ menuUser }) => (
  <StyleBrand>
    <Link href={menuUser ? "/home" : "/"}>
      <a>
        <Logotype />
      </a>
    </Link>
  </StyleBrand>
)

Brand.propTypes = {
  menuUser: PropTypes.bool,
};

Brand.defaultProps = {
  menuUser: true,
};

export default Brand;
export { Brand };
