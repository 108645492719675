import React from 'react';
import { Typography, Title, Margin } from '@components';
import Mailbox from '@icons/mailbox.svg';
import { Container } from './styles'


const ResetPasswordFeedback = () => {
  return (
    <Container>
      <Mailbox />
      <Typography as="span" weight="bold" size="32px">
        Enviamos um e-mail para você com o link de redefinição de senha
      </Typography>
    </Container>
  );
}

export default ResetPasswordFeedback;
