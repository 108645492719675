import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 570px;
  padding: 24px 120px 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;
