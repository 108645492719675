import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

// Utils
import { buildHierarchyGroup } from '@utils';

// Components
import { InputBase as Input, Button, Typography, Select } from '@components';
import { useDispatch, useSelector } from 'react-redux';

// Form & Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as groupActions } from '@store/ducks/group';
import { actions as modalActions } from '@store/ducks/modal';

// Styles
import ArrowRight from '@icons/arrow-right.svg';
import { Wrapper, ButtonWrapper } from './styles';

const ModalAddGroup = () => {
  const dispatch = useDispatch();
  const [selectGroup, setSelectGroup] = useState([]);
  const [fatherTree, setFatherTree] = useState({});
  const { loading, groups } = useSelector((state) => state.group);

  // const findFather = (groups) => {
  //   if (!father) {
  //     groups.push({ title });
  //     return groups;
  //   }

  //   const ascFamylt = fatherTree.father.split(' > ');

  //   groups.reduce((child, father) => {
  //     const { children, title } = father;
  //     if (title === fatherName) {
  //       const { title } = fatherTree;
  //       father.children = { title };

  //       return;
  //     } else if (children && children?.length) {
  //       findFather(children);
  //     } else {
  //     }
  //   }, []);
  // };

  const closeModal = useCallback(() => {
    dispatch(modalActions.close());
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      father: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Campo título é obrigatório'),
      father: Yup.string(),
    }),
    onSubmit: async (values) => {
      groups.push(values);
      dispatch(
        groupActions.sendAddGroupRequest({
          data: groups,
          showToast: true,
        })
      );
    },
  });

  useEffect(() => {
    if (groups.length) setSelectGroup(buildHierarchyGroup(groups));
  }, [groups]);

  return (
    <Wrapper>
      <Typography as="h1" weight="bold" dsize="32px">
        Criar Grupo
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Input
          name="title"
          value={formik.values.title}
          onChange={(e) => formik.setFieldValue('title', e.target.value)}
          label="Nome do grupo"
          status={
            formik.errors.title && formik.touched.title
              ? formik.errors.title
              : null
          }
        />

        {/* <Select
          name="father"
          label="Grupo"
          value={formik.values.father}
          onChange={(e) => {
            formik.setFieldValue('father', e.target.value);
          }}
          options={selectGroup}
          status={
            formik.errors.father && formik.touched.father
              ? formik.errors.father
              : null
          }
        /> */}

        <ButtonWrapper>
          <Button type="button" variant="secondary" onClick={closeModal}>
            Cancelar
          </Button>

          <Button
            type="submit"
            variant="primary"
            loading={loading}
            rightIcon={<ArrowRight />}
            block
          >
            Criar Grupo
          </Button>
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};

export default ModalAddGroup;
