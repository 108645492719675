import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import EyesOn from '@icons/eyes.svg';
import EyesOff from '@icons/eyes-hidden.svg';
import { inputMask } from '@utils/inputMask';

import { Tooltip } from '@components';
import InfoCircle from '@icons/info-circle.svg';

import { FieldBox, FieldDefault, FieldInvalid, ContainerLabel } from './Styled';

const InputBase = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    mask,
    status,
    type,
    value,
    placeholder,
    required,
    onBlur,
    onChange,
    disabled,
    variant,
    tooltipMessage,
  } = props;

  const [dinamicType, setDinamicType] = useState(type);
  const isPasswordType = dinamicType === 'password';
  const isDefaultVariant = variant === 'outside-label';
  const idName = name || label.replace(/ /g, '-').toLowerCase();

  const toggleEyes = () => setDinamicType(isPasswordType ? 'text' : 'password');

  const buttonEyes = (
    <button type="button" onClick={toggleEyes}>
      {isPasswordType ? <EyesOn /> : <EyesOff />}
    </button>
  );

  const invalid = (
    <FieldInvalid invalidStatus={status} fieldDefault={isDefaultVariant}>
      {status}
    </FieldInvalid>
  );

  const fieldGroup = (
    <>
      <ContainerLabel>
        <label htmlFor={idName}>{label}</label>

        {tooltipMessage && (
          <Tooltip content={tooltipMessage}>
            <InfoCircle />
          </Tooltip>
        )}
      </ContainerLabel>

      <div>
        <MaskedInput
          type={dinamicType}
          id={idName}
          name={idName}
          value={value}
          ref={ref || null}
          guide={false}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          autoComplete="on"
          onBlur={onBlur}
          onChange={onChange}
          mask={mask ? inputMask(mask) : mask}
        />
        {type === 'password' ? buttonEyes : false}
      </div>
      {status ? invalid : false}
    </>
  );

  const fieldBox = <FieldBox htmlFor={idName}>{fieldGroup}</FieldBox>;
  const fieldDefault = (
    <FieldDefault disabled={disabled}>{fieldGroup}</FieldDefault>
  );

  return isDefaultVariant ? fieldDefault : fieldBox;
});

InputBase.defaultProps = {
  label: '',
  variant: 'outside-label',
  status: false,
  disabled: false,
  type: 'text',
  value: '',
  mask: [],
  placeholder: '',
  required: false,
  onBlur: (e) => e,
  onChange: (e) => e,
};

export default InputBase;
export { InputBase };
