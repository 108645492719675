import React from 'react';

const Footer = () => {
  const getYear = new Date().getFullYear();

  return (
    <div>
      Copyright © {getYear} Wise up online Corporate. Todos os direitos
      reservados.
    </div>
  );
};

export default Footer;
export { Footer };
