/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react';

import { IGNORED_ERRORS, FIELDS_TO_REMOVE } from './sentry.constants';

import { version } from '../../../../../../package.json';

export class SentryAdapter {
  constructor() {
    this.storeWatchers = null;
  }

  createStoreWatcher() {
    this.storeWatchers = Sentry.createReduxEnhancer({
      actionTransformer: (action) => {
        const transformerdAction = {
          ...action,
          payload: 'hidden',
        };
        return transformerdAction;
      },
    });
  }

  init() {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      normalizeDepth: 10,
      environment: process.env.SENTRY_ENV,
      release: version,
      async beforeSend(event, hint) {
        async function sanatizeObject(baseObject, FIELDS_TO_REMOVE) {
          const body = baseObject;
          if (!FIELDS_TO_REMOVE || FIELDS_TO_REMOVE.length === 0) return body;
          const encryptFields = async (body, FIELDS_TO_REMOVE) => {
            if (Array.isArray(body)) {
              for (const object of body)
                encryptFields(object, FIELDS_TO_REMOVE);
            }
            if (typeof body === 'object') {
              for (const field in body) {
                if (typeof body[field] === 'object')
                  encryptFields(body[field], FIELDS_TO_REMOVE);
                if (FIELDS_TO_REMOVE.includes(field)) {
                  body[field] = '* * * * *';
                  // eslint-disable-next-line no-continue
                  continue;
                }
              }
            }
            return body;
          };
          return encryptFields(body, FIELDS_TO_REMOVE);
        }

        const error = hint.originalException;
        event.message = error.message;

        if (
          event.contexts &&
          event.contexts.state &&
          event.contexts.state.state
        ) {
          const transformedState = await sanatizeObject(
            event.contexts.state.state,
            FIELDS_TO_REMOVE
          );
          event.contexts.state.state = transformedState;
          return event;
        }
        return event;
      },
      ignoreErrors: IGNORED_ERRORS,
      tracesSampleRate: 0.1,
    });
  }

  captureException(transactionName, severityLevel, error, details) {
    Sentry.withScope((scope) => {
      scope.setLevel(severityLevel);
      scope.setTransactionName(transactionName);
      if (details) {
        scope.setExtra('Error Details', details);
      }
      Sentry.captureException(error);
    });
  }

  captureLog(message, severityLevel, details) {
    Sentry.withScope((scope) => {
      scope.setLevel(severityLevel);
      scope.setTransactionName(message);
      if (details) {
        scope.setExtra('Details', details);
      }
      scope.setFingerprint([message, severityLevel]);
      Sentry.captureMessage(message);
    });
  }

  captureUIException(error, { componentStack }) {
    Sentry.withScope((scope) => {
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
      errorBoundaryError.stack = componentStack;

      scope.setTag('Origem', 'ErrorBoundary');
      scope.setExtra('Cause', errorBoundaryError);
      scope.setExtra('stack', error.stack);
      scope.setExtra('Componentstack', componentStack);
      scope.setExtra('Name', error.name);
      scope.setExtra('ErrorMessage', error.message);
      Sentry.configureScope((scope) =>
        scope.setTransactionName('Capturado com Error Boundary')
      );
      const eventId = Sentry.captureException(error, {
        contexts: { react: { componentStack } },
      });
      scope.setExtra('eventId', eventId);
    });
  }

  captureHTTPException(error) {
    Sentry.withScope((scope) => {
      const requestUrl = error?.config?.url;
      const statusCode = error?.response?.status;
      const requestMethod = error?.config?.method;
      const errorMessage = error?.response?.data?.error;
      const genericErrorMessage = error?.message;
      scope.setFingerprint([requestUrl, requestMethod, String(statusCode)]);
      scope.setExtra('requestUrl', requestUrl);
      scope.setExtra('statusCode', statusCode);
      scope.setExtra('requestMethod', requestMethod);
      scope.setExtra('errorMessage', errorMessage);
      scope.setExtra('genericErrorMessage', genericErrorMessage);
      scope.setExtra('date', new Date());
      scope.setTag('requestUrl', requestUrl);
      scope.setTag('statusCode', statusCode);
      scope.setTag('requestMethod', requestMethod);
      Sentry.configureScope((scope) =>
        scope.setTransactionName('Axios request error')
      );
      Sentry.captureException(error);
    });
  }
}
