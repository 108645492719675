import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

// STYLE
import { SidebarStyle } from './Styled';
import navigation from './navigation';

const Sidebar = () => {
  const url = useRouter().pathname;

  const getClassName = (key) => {
    switch (key) {
      case url:
        return 'active';
      case '/':
        return 'disable';
      default:
        return '';
    }
  }

  const menuList = navigation.map((item) => (
    <li key={item.label} className={getClassName(item.href)}>
      { item.href === '/' ? <span>Em breve</span> : false}
      <Link href={item.href}>
        <a>
          <div className="box-menu-icon-title">
            {item.icon}
            <span>{item.label}</span>
          </div>

          <span className="line-active-menu"></span>
        </a>
      </Link>
    </li>
  ));

  return (
    <SidebarStyle>
      <ul>{menuList}</ul>
    </SidebarStyle>
  );
};

export default Sidebar;
export { Sidebar };
