import * as types from './types';

export const changeGroup = (payload) => ({
  type: types.CHANGE_GROUP,
  payload,
});
export const sendAddGroupRequest = (payload) => ({
  type: types.SEND_ADD_GROUP_REQUEST,
  payload,
});

export const sendAddGroupSuccess = ({ toast, ...payload }) => ({
  type: types.SEND_ADD_GROUP_SUCCESS,
  payload,
  toast,
});

export const sendAddGroupFailure = (payload) => ({
  type: types.SEND_ADD_GROUP_FAILURE,
  payload,
});

export const getAllGroupsRequest = (payload) => ({
  type: types.GET_ALL_GROUPS_REQUEST,
  payload,
});

export const getAllGroupsSuccess = (payload) => ({
  type: types.GET_ALL_GROUPS_SUCCESS,
  payload,
});

export const getAllGroupsFailure = (payload) => ({
  type: types.GET_ALL_GROUPS_FAILED,
  payload,
});
