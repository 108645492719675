import React from 'react';
import PropTypes from 'prop-types';

// Style
import { CheckboxLabel } from './Styled';

const Checkbox = (props) => {
  const { label, checked, id, name, onChange, disabled } = props;

  return (
    <CheckboxLabel htmlFor={name}>
      <input
        type="checkbox"
        id={id || name}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <div />
      {label}
    </CheckboxLabel>
  );
};

Checkbox.defaultProps = {
  id: '',
  disabled: false,
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
export { Checkbox };
