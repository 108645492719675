const aroundTime = (arr) => {
  let [hh, mm, ss] = arr;

  if (ss > 0) {
    ss = '00';
    mm++;
    mm = mm.toString();
    mm = mm.length > 1 ? mm : '0' + mm;
  }
  if (mm >= 60) {
    mm = '00';
    hh++;
    hh = hh.toString();
    hh = hh.length > 1 ? hh : '0' + hh;
  }
  return hh + ':' + mm + ':' + ss;
};

const convertSeconds = (sec) => {
  const hrs = Math.floor(sec / 3600);
  const min = Math.floor((sec - hrs * 3600) / 60);
  let seconds = sec - hrs * 3600 - min * 60;
  seconds = Math.round(seconds * 100) / 100;

  let result = hrs < 10 ? '0' + hrs : hrs;
  result += ':' + (min < 10 ? '0' + min : min);
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  return result;
  // EXAMPLE: 123 = 00:02:03
};

const secondsToHHMMSS = (seconds, aroundSeconds = false) => {
  const number = parseInt(seconds || 0);

  const timeStringHHMMSS = convertSeconds(number);

  if (aroundSeconds) {
    return aroundTime(timeStringHHMMSS.split(':'));
  }
  return timeStringHHMMSS;
  // EXAMPLE: 10:45:23
};

/**
 * Convert seconds to HH:MM:SS or HH:MM formats
 *
 * @param {number} time
 * @param { boolean } with_seg
 */
const segToHour = (time, with_seg = true) => {
  let hours = Math.floor(time / 3600)
  let minutes = Math.floor((time % 3600) / 60)
  let seconds = (time % 60)

  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  hours = hours < 10 ? '0' + hours : hours

  return with_seg ? `${hours}:${minutes}${seconds}s` : `${hours}:${minutes}`
}

export default secondsToHHMMSS;
export { secondsToHHMMSS, segToHour };
