export const IGNORED_ERRORS = [
  '401 Unauthorized',
  '422 Unprocessable Entity',
  'Request aborted',
  'Request failed with status code 401',
  'Request failed with status code 403',
  'Request failed with status code 404',
  'Request failed with status code 422',
  'Request failed with status code 504',
];

export const FIELDS_TO_REMOVE = [
  'name',
  'email',
  'token',
  'phone',
  'mobilephone',
  'document',
  'rg',
  'cpf',
  'dateOfBirthday',
  'street',
  'streetNumber',
  'complement',
  'neighborhood',
  'city',
  'state',
  'postalCode',
  'createdbyName',
  'addressUpdated',
  'documentUpdated',
  'emailUpdated',
  'newPassword',
  'auth_token',
  'paidAt',
  'brand',
  'firstSix',
  'lastFour',
  'expireMonth',
  'expireYear',
  'holderName',
  'contactName',
  'contactEmail',
  'contactPhone',
  'createdByName',
  'lastModifiedByName',
  'periods',
  'age',
  'first_name',
  'last_name',
  'full_name',
  'gender',
  'date_of_birth',
  'nickname',
];
