import { css } from 'styled-components';
import * as token from '@theme';

const ToastStyles = () => css`
  .Toastify__toast {
    border-radius: 7px;
    font-family: ${token.typography.fontFamily};
    padding: 15px;
    font-size: 1rem;
  }

  .Toastify__toast--error {
    background-color: ${token.color.error};
  }

  .Toastify__toast--info {
    background-color: ${token.color.info};
  }

  .Toastify__toast--success {
    background-color: ${token.color.success};
  }

  .Toastify__toast--warning {
    background-color: ${token.color.warning};
  }
`;

export default ToastStyles;
