import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  limitPage: 25,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    /**
     * ---------------------------------------------------
     * MODAL
     * ---------------------------------------------------
     */
    case types.SET_PAGE_SIZE:
      return { ...state, limitPage: action.payload.limit };
    case types.GET_PAGE_SIZE:
      return { ...state };
    default:
      return state;
  }
}

export { actions, sagas };
