import { HYDRATE } from 'next-redux-wrapper';

// TYPES
import * as types from './types';

// ACTIONS
import * as actions from './actions';

// SAGAS
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: false,
  loading: false,
  graphics: {},
  tables: {},
  widgets: {},
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    // case HYDRATE:
    //   return {
    //     ...state,
    //     ...action.payload.dashboard,
    //   };

    /**
     * ---------------------------------------------------
     * DASHBOARD - GRAPHIC_STUDY_HOURS
     * ---------------------------------------------------
     */

    case types.GET_GRAPHIC_STUDY_HOURS_REQUEST:
      return { ...state, error: false, loading: true };
    case types.GET_GRAPHIC_STUDY_HOURS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        graphics: {
          ...state.graphics,
          ...action.payload,
        },
      };
    case types.GET_GRAPHIC_STUDY_HOURS_FAILURE:
      return { ...state, error: true, loading: false };

    /**
     * ---------------------------------------------------
     * DASHBOARD - GRAPHIC_ACCESS_FREQUENCY
     * ---------------------------------------------------
     */

    case types.GET_GRAPHIC_ACCESS_FREQUENCY_REQUEST:
      return { ...state, error: false, loading: true };
    case types.GET_GRAPHIC_ACCESS_FREQUENCY_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        graphics: {
          ...state.graphics,
          ...action.payload,
        },
      };
    case types.GET_GRAPHIC_ACCESS_FREQUENCY_FAILURE:
      return { ...state, error: true, loading: false };

    /**
     * ---------------------------------------------------
     * DASHBOARD - GRAPHIC_COMPLETED_STEPS
     * ---------------------------------------------------
     */

    case types.GET_GRAPHIC_COMPLETED_STEPS_REQUEST:
      return { ...state, error: false, loading: true };
    case types.GET_GRAPHIC_COMPLETED_STEPS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        graphics: {
          ...state.graphics,
          ...action.payload,
        },
      };
    case types.GET_GRAPHIC_COMPLETED_STEPS_FAILURE:
      return { ...state, error: true, loading: false };

    /**
     * ---------------------------------------------------
     * DASHBOARD - ACHIEVEMENT
     * ---------------------------------------------------
     */

    case types.GET_DASH_ACHIEVEMENT_REQUEST:
      return { ...state, error: false, loading: true };
    case types.GET_DASH_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case types.GET_DASH_ACHIEVEMENT_FAILURE:
      return { ...state, error: true, loading: false };

    /**
     * ---------------------------------------------------
     * DASHBOARD - STUDY_HOURS
     * ---------------------------------------------------
     */

    case types.GET_DASH_STUDY_HOURS_REQUEST:
      return { ...state, error: false, loading: true };
    case types.GET_DASH_STUDY_HOURS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case types.GET_DASH_STUDY_HOURS_FAILURE:
      return { ...state, error: true, loading: false };

    /**
     * ---------------------------------------------------
     * DASHBOARD - MOST_ACCESSED_CONTENT
     * ---------------------------------------------------
     */

    case types.GET_DASH_MOST_ACCESSED_CONTENT_REQUEST:
      return { ...state, error: false, loading: true };
    case types.GET_DASH_MOST_ACCESSED_CONTENT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        tables: {
          ...state.tables,
          ...action.payload,
        },
      };
    case types.GET_DASH_MOST_ACCESSED_CONTENT_FAILURE:
      return { ...state, error: true, loading: false };

    /**
     * ---------------------------------------------------
     * DASHBOARD - WIDGETS
     * ---------------------------------------------------
     */

    case types.GET_DASH_WIDGETS_REQUEST:
      return { ...state, error: false, loading: true };
    case types.GET_DASH_WIDGETS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        widgets: action.payload.widgets,
      };
    case types.GET_DASH_WIDGETS_FAILURE:
      return { ...state, error: true, loading: false };

    /**
     * ---------------------------------------------------
     * DEFAULT
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
