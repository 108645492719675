import React from 'react';
import PropTypes from 'prop-types';
import * as theme from '@theme';

import { TypographyStyle } from './Styled';

const Typography = React.memo(
  ({
    as,
    children,
    color,
    dsize,
    dheight,
    family,
    height,
    size,
    float,
    weight,
    transform,
    decoration,
    className,
    cursor,
    align,
  }) => {
    let cursorMouse = 'unset';
    if (as === 'a' || cursor) {
      cursorMouse = 'pointer';
    }

    const decorationLine = decoration === 'underline' ? '' : decoration;
    const decorationClass = decoration === 'underline' ? 'link-hover' : '';

    return (
      <TypographyStyle
        className={`text-component ${decorationClass} ${className}`}
        as={as}
        color={color}
        family={family}
        height={height}
        size={size}
        float={float}
        dsize={dsize}
        dheight={dheight}
        transform={transform}
        decoration={decorationLine}
        weight={weight}
        cursor={cursorMouse}
        align={align}
        tabIndex={as === 'a' ? `0` : ``}
      >
        {children}
      </TypographyStyle>
    );
  }
);

Typography.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  decoration: PropTypes.string,
  dheight: PropTypes.string,
  dsize: PropTypes.string,
  family: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
  float: PropTypes.string,
  transform: PropTypes.string,
  align: PropTypes.string,
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Typography.defaultProps = {
  as: 'span',
  children: <></>,
  className: '',
  color: '#000000',
  cursor: null,
  decoration: 'none',
  size: '1rem',
  float: 'none',
  dsize: '1rem',
  dheight: 'normal',
  height: 'normal',
  family: 'Avenir',
  transform: 'initial',
  weight: 'normal',
  align: 'left',
};

export default Typography;
export { Typography };
