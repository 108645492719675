function arrGroups(arr) {
  const arrOut = [];

  const arrGroups = (array) => {
    array.reduce((flat, toFlatten) => {
      const { children, title } = toFlatten;
      arrOut.push({
        selected: false,
        label: title,
      });
      if (children?.length) arrGroups(children);
    }, []);

    return arrOut;
  };

  return arrGroups(arr);
}

function buildGroups(groups) {
  return groups.reduce((acc, group) => {
    if (group.children) {
      return [...acc, group.title, ...buildGroups(group.children)];
    }
    return [...acc, group.title];
  }, []);
}

export function getGroups(groups) {
  const arrayItens = buildGroups(groups);
  const arrGroups = [];
  arrayItens.forEach((item) => {
    arrGroups.push({
      value: item?.trim(),
      label: item?.trim(),
    });
  });

  return arrGroups;
}

export default arrGroups;
export { arrGroups };
