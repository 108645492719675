import React from 'react';
import PropTypes from 'prop-types';

// Style
import SkeletonStyle from './Styled';

const Skeleton = (props) => {
  let { borderRadius, width, height, round } = props;
  const rows = Array.from(Array(parseInt(props.rows)).keys());

  if (!round)
    return rows.map((row, index) => (
      <SkeletonStyle key={index} theme={{ borderRadius, width, height }} />
    ));

  borderRadius = '50%';
  height = width;
  return <SkeletonStyle theme={{ borderRadius, width, height }} />;
};

Skeleton.defaultProps = {
  borderRadius: '7px',
  round: false,
  height: '100%',
  width: '100%',
  rows: 1,
};

Skeleton.prototypes = {
  round: PropTypes.bool,
  row: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Skeleton;
export { Skeleton };
