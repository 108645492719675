import Styled, { css } from 'styled-components';
import * as tokens from '@theme';
// import * as theme from '@theme';

const TypographyStyle = Styled.div`
  color: ${({color}) => color};
  font-family: ${({family}) => family};
  line-height: ${({height}) => height};
  font-size: ${({size}) => size};
  float: ${({float}) => float};
  text-transform: ${({transform}) => transform};
  text-decoration: ${({decoration}) => decoration};
  font-weight: ${({weight}) => weight};
  cursor: ${({cursor}) => cursor};
  text-align: ${({align}) => align};
  display: block;

  @media all and (min-width: ${tokens.breakpoints.xs}) {
    font-size: ${({dsize}) => dsize};
    line-height: ${({dheight}) => dheight};
  }

  &.link-hover {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding-bottom: 4px;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.2rem;

      background: ${({color}) => color};

      position: absolute;
      bottom: 0;
      left: 0%;

      /* transition: all 0.25s ease-in-out; */
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0.2rem;

      background: ${({color}) => color};

      position: absolute;
      bottom: 0;
      left: -20%;

      transition: all 0.45s ease-in-out;
    }

    &:hover {
      &:after {
        left: 100%;
        transition: all 0.45s ease-in-out;
      }

      &:before {
        width: 100%;
        left: 0;
        transition: all 0.45s ease-in-out;
        transition-delay: 0.2s;
      }
    }
  }
`;

export default TypographyStyle;
export { TypographyStyle };
