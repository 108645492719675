import React from 'react';
import PropTypes from 'prop-types';

// Style
import MarginStyle from './Styled';

const MarginHome = ({ left, top, right, bottom }) => (
  <MarginStyle theme={{ left, top, right, bottom }} />
);

MarginHome.defaultProps = {
  left: 1,
  top: 0,
  right: 1,
  bottom: 1,
};

MarginHome.prototypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
};

export default MarginHome;
export { MarginHome };
