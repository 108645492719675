import Styled, { css } from 'styled-components';

export const ContainerLabel = Styled.div`
    margin:0;
    padding: 0;
    display: flex;
    align-items: center;
  
    >label{
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
      color: #626262;

    }
`;

export const buttonField = css`
  > button {
    transition: all 0.2s ease-in-out 0s;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      width: 24px;
      height: 24px;

      > path,
      > rect {
        fill: #ababab;
      }
    }
  }
`;

export const FieldInvalid = Styled.dd`
  color: rgb(255, 0, 75);
  margin: ${({ fieldDefault }) => (fieldDefault ? '0' : '0')};
  text-align: left;
  font-size: ${({ invalidStatus }) => (invalidStatus ? '12px' : '0')};
  transition: all 0.9s ease-in-out 0s;
`;

export const FieldBox = Styled.label`
  background: #F0F0F0;
  border: 2px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 10px 0;
  padding: 2px 20px;
  display: block;
  height: auto;
  transition: all 0.2s ease-in-out 0s;

 label {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    text-align: left;
    color: #626262;
  }

  > div {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    > button {
      background: transparent;
      transition: all 0.2s ease-in-out 0s;
      border: none;
      outline: none;
    }

    > input {
      background: transparent;
      border: none;
      outline: none;
      font-size: 18px;
      transition: all 0.2s ease-in-out 0s;
      font-family: 'Avenir';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      color: #000000;
      flex: auto;

      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    ${buttonField}
  }

  &:focus-within {
    background: transparent;
    border-color: #00EB84;
  }
`;

export const FieldDefault = Styled.div`
   
  text-align: left;
  transition: all 0.2s ease-in-out 0s;
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  flex-wrap: nowrap;
  background: #F5F5F5;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  height: 64px;
  margin: 5px 0 10px;
  padding: 7px 25px;
  transition: all 0.2s ease-in-out 0s;

 
     
  > label {
    font-family: Avenir;
    font-weight: 700;
    font-size: 14px;
    color: #626262;
    margin: 0;
  }

  > div {
    margin: 0;
    > input {
      background: transparent;
      outline: none;
      border: none;
      transition: all 0.2s ease-in-out 0s;
      
      height: 100%;
      width: 100%;
      
 
      font-family: 'Avenir';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #333;


      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

       
    }

    ${buttonField}
  }

  &:focus-within {
      border-color: ${({ theme }) => theme.color.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: #f5f5f5;
      border: 1px solid #e3e3e3;
      > label {
        color: #626262;
      }
    `}


`;
