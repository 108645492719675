import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components SVG
import ArrowDown from '@icons/chevron-down-smaller.svg';

// Style
import { DropDownStyle, ListDropDown, DropDownContainer } from './Styled';

const theme = {
  top: '54px',
  right: '0',
  overflow: 'hidden',
  bottom: 'initial',
  left: 'initial',
};

const MenuDropDownUserHeader = (props) => {
  const [visible, setVisible] = useState(false);

  const { children, label } = props;

  const toggleDropDown = () => {
    setVisible(!visible);
  };

  const icon = props.icon || <ArrowDown className="chevron-down" />;

  const buildRender = () => {
    let DropDownClass = '';

    if (visible) {
      DropDownClass = `show user-menu`;
    }

    return (
      <DropDownStyle>
        <DropDownContainer>
          <button
            className="text user-menu"
            onClick={toggleDropDown}
            type="button"
          >
            <b>{label}</b>
            {icon}
          </button>
          <ListDropDown
            className={DropDownClass}
            theme={{
              visible,
              left: theme.left,
              top: theme.top,
              right: theme.right,
              bottom: theme.bottom,
              overflow: theme.overflow,
            }}
          >
            {children}
          </ListDropDown>
        </DropDownContainer>
      </DropDownStyle>
    );
  };

  return buildRender();
};

MenuDropDownUserHeader.defaultProps = {
  children: <></>,
  icon: false,
  label: '',
};

MenuDropDownUserHeader.prototypes = {
  children: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
};

export default MenuDropDownUserHeader;
export { MenuDropDownUserHeader };
