import React from 'react';
import HeadNext from 'next/head';
import PropTypes from 'prop-types';

const Head = ({ children, ...restProps }) => {
  return (
    <HeadNext>
      <title>{restProps.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
      />
      {children}
    </HeadNext>
  );
};

Head.defaultProps = {
  children: null,
};

Head.propTypes = {
  children: PropTypes.element,
};

export default Head;
export { Head };
