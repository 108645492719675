import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components SVG
import ArrowDown from '@icons/chevron-down.svg';

// Style
import { DropDownStyle, ListDropDown, DropDownContainer } from './Styled';

const MenuDropDown = (props) => {
  let {
    children,
    variant,
    left,
    top,
    right,
    bottom,
    label,
    userMenu,
    overflow,
  } = props;

  const [visible, setVisible] = useState(false);
  const listDropDownEl = useRef();
  const containerDropDownEl = useRef();

  const handleOutsideClick = (e) => {
    if (!listDropDownEl.current.contains(e.target) && !containerDropDownEl.current.contains(e.target)) {
      setVisible(false);
    }
  }
  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, []);

  const toggleDropDown = () => setVisible(!visible);

  const icon = props.icon || <ArrowDown className="chevron-down" />;

  const buildRender = () => {
    let DropDownClass = '';
    if (visible) DropDownClass = `show ${DropDownClass}`;
    if (userMenu) {
      DropDownClass = 'user-menu';
      variant = variant === 'text' ? `${variant} user-menu` : variant;
    }

    return (
      <DropDownStyle>
        <DropDownContainer ref={containerDropDownEl}>
          <button className={variant} onClick={toggleDropDown} type="button">
            {label}
            {icon}
          </button>
          <ListDropDown
            ref={listDropDownEl}
            className={DropDownClass}
            theme={{ visible, left, top, right, bottom, overflow }}
          >
            {children}
          </ListDropDown>
        </DropDownContainer>
      </DropDownStyle>
    );
  };

  return buildRender();
};

MenuDropDown.defaultProps = {
  left: 'initial',
  top: '50px',
  right: 'initial',
  bottom: 'initial',
  variant: 'button',
  children: <></>,
  icon: false,
  label: '',
  userMenu: false,
  overflow: 'hidden',
};

MenuDropDown.prototypes = {
  variant: PropTypes.oneOf(['button', 'text']),
  overflow: PropTypes.oneOf(['hidden', 'visible']),
  children: PropTypes.element.isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
  userMenu: PropTypes.bool,
};

export default MenuDropDown;
export { MenuDropDown };
