import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { actions as pageSizeActions } from '@store/ducks/pageSize';
import ChevronLeft from '@icons/chevron-left.svg';
import ChevronRight from '@icons/chevron-right.svg';
import { ShortPaginationStyle } from './Styled';


const Button = ({ text, className, onClick, disabled, children }) => (
    <button
        type="button"
        className={className}
        onClick={onClick}
        disabled={disabled}
    >
        {children}
    </button>
);

const ShortPagination = ({ currentPage, placeholder, total, rightSide }) => {
    const { limitPage } = useSelector((state) => state.pageSize);
    const [limit, setLimit] = useState(limitPage || 25);

    const countPages = Math.ceil(total ? total / (limit || 25) : 0);
    const pageIndex = Array.from(Array(countPages).keys());

    const dispatch = useDispatch();
    const router = useRouter();
    const { pathname, query } = router;

    const current = total ? parseInt(query.page || 1) : total;

    const [pageValue, setPageValue] = useState(current);

    const showLastPage = !pageIndex.length || current === pageIndex.length;
    const showFirstPage = !pageIndex.length || current === 1;


    Router.events.on('routeChangeComplete', () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setPageValue(Number(query.page || 1))
    });

    const handleClick = (index) => {
        router.push({
            pathname,
            query: { ...query, page: index },
        });
    };

    const handleSubmit = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (pageValue > pageIndex.length) {
            setPageValue(pageIndex.length)
            return handleClick(pageIndex.length)
        }

        if (!pageValue || pageValue < pageIndex[1]) {
            setPageValue(pageIndex[1])
            return handleClick(pageIndex[1])
        }
        handleClick(pageValue)
    };

    const handleInputChange = ({ target: { value } }) => {
        if (value.match(/^[0-9]*$/)) {
            setPageValue(Number(value))
        };
    }

    const handleInputBlur = () => {
        setPageValue(query.page)
    }

    const previous = (
        <Button
            disabled={showFirstPage}
            onClick={() => handleClick(current - 1)}
        >
            <ChevronLeft />
        </Button>
    );

    const next = (
        <Button
            disabled={showLastPage}
            onClick={() => handleClick(current + 1)}
        >
            <ChevronRight />
        </Button>
    );

    const input = (
        <input
            id='singlPaginationNumber'
            type="text"
            value={pageValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder={placeholder}
        />
    )

    const rightSideElement = (
        <ShortPaginationStyle onSubmit={handleSubmit} rightSide>
            {next}
            {input}
            {previous}
            <label htmlFor="singlPaginationNumber">Página</label>
        </ShortPaginationStyle>
    )

    const leftSideElement = (
        <ShortPaginationStyle onSubmit={handleSubmit}>
            <label htmlFor="singlPaginationNumber">Página</label>
            {previous}
            {input}
            {next}
        </ShortPaginationStyle>
    )


    useEffect(() => {
        dispatch(pageSizeActions.getPageSize());
        window.scrollTo(0, 0);
        if (!current) {
            router.push({
                pathname,
                query: { ...query, page: 1 },
            });
        }
    }, []);

    useEffect(() => {
        setLimit(limitPage);
        router.push({
            pathname,
            query: { ...query, page: 1 },
        });
    }, [limitPage]);

    useEffect(() => {
        if (!total) {
            router.push({
                pathname,
                query: { ...query, page: 0 },
            });
        }
    }, [current]);

    return rightSide ? rightSideElement : leftSideElement

};

ShortPagination.defaultProps = {
    onSubmit: () => null,
    placeholder: '',
    currentPage: 0,
};

ShortPagination.prototypes = {
    onSubmit: PropTypes.func,
    currentPage: PropTypes.number,
    placeholder: PropTypes.string.isRequired,
};

export default ShortPagination;
export { ShortPagination };
