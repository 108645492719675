const color = {
  primary: '#00EB84',
  primaryHover: '#50ffb2',
  secondary: '#636363',

  white: '#ffffff',
  light: '#E5E5EA',
  medium: '#636363',
  dark: '#2C2C2E',
  black: '#000000',

  grey0: '#F2F2F7',
  grey1: '#E5E5EA',
  grey2: '#D1D1D6',
  grey3: '#C7C7CC',
  grey4: '#AEAEB2',
  grey5: '#8E8E93',
  grey6: '#636366',
  grey7: '#48484A',
  grey8: '#3A3A3C',
  grey9: '#2C2C2E',
  grey10: '#111111',

  error: '#FF3B30',
  warning: '#fece00',
  success: '#34C759',
  info: '#015feb',

  indigo: '#5856D6',
  purple: '#AF52DE',
  pink: '#FF2D55',
  red: '#FF3B30',
  orange: '#FF9500',
  yellow: '#fece00',
  green: '#00a032',
  teal: '#5AC8FA',
  blue: '#015feb',
};

export default color;
export { color };
