import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
// eslint-disable-next-line import/no-cycle
import { sagas } from './sagas';

const INITIAL_STATE = {
  visible: false,
  type: null,
  props: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.modal,
      };
    /**
     * ---------------------------------------------------
     * MODAL
     * ---------------------------------------------------
     */
    case types.OPEN:
      return {
        ...state,
        visible: true,
        type: action.payload.type,
        props: action.payload.props,
      };
    case types.CLOSE:
      return {
        ...state,
        visible: false,
        type: null,
        props: action?.payload?.props || null,
      };
    default:
      return state;
  }
}

export { actions, sagas };
