import { callApiBase } from './base';

const planApi = {
  getPlan: ({ token }) =>
    callApiBase({
      title: 'Plan - Values',
      endpoint: '/v1/user/info',
      method: 'GET',
      token,
    }),
};

export default planApi;
export { planApi };
