import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
// eslint-disable-next-line import/no-cycle
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  loading: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.plan,
      };
    /**
     * ---------------------------------------------------
     * PLAN
     * ---------------------------------------------------
     */
    case types.PLAN_REQUEST:
      return { ...state, loading: true, error: false };
    case types.PLAN_REQUEST_SUCCESS:
      return { ...state, ...action.payload, loading: false, error: false };
    case types.PLAN_REQUEST_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

export { actions, sagas };
