import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { actions as pageSizeActions } from '@store/ducks/pageSize';
import ChevronRight from '@icons/chevron-right.svg';
import ChevronRightAll from '@icons/chevrons-right.svg';
import ChevronLeft from '@icons/chevron-left.svg';
import ChevronLeftAll from '@icons/chevrons-left.svg';
import { PaginationStyle } from './Styled';

const Button = ({ text, className, onClick, disabled, children }) => (
  <button
    type="button"
    className={className}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

const Pagination = (props) => {
  const { total } = props;
  const { limitPage } = useSelector((state) => state.pageSize);
  const [limit, setLimit] = useState(limitPage || 25);
  const dispatch = useDispatch();
  const router = useRouter();
  const countPages = Math.ceil(total ? total / (limit || 25) : 0);

  const pageIndex = Array.from(Array(countPages).keys());
  const { pathname, query } = router;
  const pageCurrent = props.pageCurrent || parseInt(query.page);
  const showLastPage = pageCurrent === pageIndex.length;
  const showFirstPage = pageCurrent === 1;

  Router.events.on('routeChangeComplete', () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });

  const handleClick = (index) => {
    router.push({
      pathname,
      query: { ...query, page: index },
    });
  };

  const pagesIndex = (number) => (
    <Button
      key={number}
      className={number == pageCurrent ? 'active' : 'number'}
      onClick={() => handleClick(number)}
    >
      {number}
    </Button>
  );

  const previous = (
    <Button
      disabled={showFirstPage}
      onClick={() => handleClick(pageCurrent - 1)}
    >
      <ChevronLeft />
    </Button>
  );

  const previousAll = (
    <Button disabled={showFirstPage} onClick={() => handleClick(1)}>
      <ChevronLeftAll />
    </Button>
  );

  const next = (
    <Button
      disabled={showLastPage}
      onClick={() => handleClick(pageCurrent + 1)}
    >
      <ChevronRight />
    </Button>
  );

  const nextAll = (
    <Button
      disabled={showLastPage}
      onClick={() => handleClick(pageIndex.length)}
    >
      <ChevronRightAll />
    </Button>
  );

  useEffect(() => {
    dispatch(pageSizeActions.getPageSize());
    window.scrollTo(0, 0);
    if (!pageCurrent) {
      router.push({
        pathname,
        query: { ...query, page: 1 },
      });
    }
  }, []);

  useEffect(() => {
    setLimit(limitPage);
    router.push({
      pathname,
      query: { ...query, page: 1 },
    });
  }, [limitPage]);

  if (total <= limit || !total) return <></>;

  return (
    <PaginationStyle>
      {previousAll}
      {previous}
      {pageIndex.map((page) => pagesIndex(page + 1))}
      {next}
      {nextAll}
    </PaginationStyle>
  );
};

Pagination.defaultProps = {
  total: 0,
  pageCurrent: 0,
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  pageCurrent: PropTypes.number,
};

export default Pagination;
export { Pagination };
