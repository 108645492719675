import Styled from 'styled-components';

const ShortPaginationStyle = Styled.form`
  display: flex;
  justify-content: left;
  flex: 1 1 50%;
  transition: all 0.4s ease-in-out 0s;
  width: 25%;
  direction: ${({ rightSide }) => rightSide ? 'rtl' : 'ltr'};
  margin: auto 0;
  max-width: 100%;

  > label {
    text-align: center;
    font-weight: 500;
    margin: auto 10px auto 0;
  }

  > button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 8px;
    letter-spacing: .2rem;
    display: flex;
    text-align: center;
    margin: auto 0;

    &.number {
      padding: 10px 15px;
    }
    &.active {
      font-weight: bold;
      background: ${({ theme }) => theme.color.primary};
      border-radius: 5px;
      color: white;
      padding: 5px 10px;
      letter-spacing: inherit;
      margin: 0 5px;
    }
    &:disabled {
      opacity: .7;
      cursor: no-drop;
      transition: all .3s ease-out;
    }
  }

  > input {
    text-align: center;
    border-color: inherit;
    border-radius: 8px;
    background: #edf1f2;
    border: 1px solid #e0e0e0;
    font-family: 'Avenir';
    font-style: normal;
    outline: none;
    color: #111111;
    width: 60px;
    display: block;
    font-size: 0.9rem;
    line-height: 1rem;
    padding: 0.6rem 15px;

    &:active,
    &:focus,
    &:hover {
      border-color: #fff;
      box-shadow: inset 0 0 6px 0px #e0e0e0;
      border: 1px solid #e0e0e0;
    }

    &:placeholder {
      color: #a3a3a3;
    }
  }
`;

export default ShortPaginationStyle;
export { ShortPaginationStyle };
