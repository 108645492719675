import { createGlobalStyle } from 'styled-components';
import * as theme from './tokens';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Avenir';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: local('Avenir Roman'), local('Avenir-Roman'),
        url('/assets/fonts/Avenir-Roman-400.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Avenir';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: local('Avenir Medium'), local('Avenir-Medium'),
        url('/assets/fonts/Avenir-Medium-500.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Avenir';
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: local('Avenir Heavy'), local('Avenir-Heavy'),
        url('/assets/fonts/Avenir-Heavy-800.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Avenir';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: local('Avenir Black'), local('Avenir-Black'),
        url('/assets/fonts/Avenir-Black-900.woff2') format('woff2');
  }


  body {
    position: relative;
    font-style: normal;
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.fontWeight.default};
    text-align: ${theme.typography.textAlign};
    font-size: ${theme.typography.baseSize};
    color: ${theme.color.grey10};
    background-color: #fff;
    z-index: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    border: 0;

    a {
      color: ${theme.color.dark};
      font-weight: ${theme.typography.fontWeight.default};
      text-decoration: none;
      outline: none;

      &:hover {
        color: ${theme.color.primary}
      }
    }

    & p,
    & span,
    & label {
      line-height: ${theme.typography.paragraph.lineHeight};
      font-weight: ${theme.typography.fontWeight.default};
      color: ${theme.color.medium};
      font-family: ${theme.typography.fontFamily};
    }

    & b {
      font-weight: ${theme.typography.fontWeight.bold};
    }

    & small {
      font-size: ${theme.typography.fontSize.small};
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      line-height: normal;
      letter-spacing: 0em;
      color: ${theme.color.black};
      font-family: ${theme.typography.family};
      font-weight: ${theme.typography.fontWeight.bold};
    }

    & ul {
      list-style: none;
      padding: 0;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover, 
    & input:-webkit-autofill:focus,
    & textarea:-webkit-autofill,
    & textarea:-webkit-autofill:hover,
    & textarea:-webkit-autofill:focus,
    & select:-webkit-autofill,
    & select:-webkit-autofill:hover,
    & select:-webkit-autofill:focus {
      border: none;
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    }
  }

  * {
    box-sizing: border-box;
    direction: ltr;
    font-feature-settings: 'kern';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ::selection { 
      background: ${theme.color.primary};
      color: ${theme.color.dark};
    }

    ::after,
    ::before {
      box-sizing: border-box;
    }
  }
`;

export default GlobalStyle;
export { GlobalStyle };
