import Styled from 'styled-components';

const IconStyle = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleStyle = Styled.div`
  display: block;
  margin: 0;
  margin-bottom: 15px;

  & h1 {
    color: #333333;
    line-height: 33px;
    font-weight: bold;
    font-size: 2em;
    margin: 0;
  }

  & h2 {
    color: #333333;
    line-height: 33px;
    font-weight: bold;
    font-size: 1.8em;
    margin: 0;
  }
  
  & h3 {
    color: #333333;
    line-height: 33px;
    font-weight: bold;
    font-size: 18px;
        margin: 0;
  } 

  p {
    font-size: .90em;
    margin-top: 8px;
    margin-bottom: 0px;
    color: #8C8C8C;
  }

  > div {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 5px 0;

    & svg {
      margin-right: 10px;
      width: auto;  
    }
  }
`;

export default TitleStyle;
export { TitleStyle, IconStyle };
