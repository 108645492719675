import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  h1 {
    margin-top: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;

  button {
    width: 281px;
    height: 48px;
    position: relative;
    svg {
      position: absolute;
      right: 28px;
      width: 24px;
    }
  }
  > .secondary {
    button {
      width: 140px;
      min-width: 140px;
      margin-right: 16px;
    }
  }
`;
