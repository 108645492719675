import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 570px;
  padding: 24px 60px;
`;

export const SubTitle = styled.div`
  color: #8c8c8c;
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  margin: 5px 0 35px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 140px;
    min-width: auto;
    height: 48px;
    margin: 0 8px;
  }
`;
