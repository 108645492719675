import Styled from 'styled-components';

const BoxStyle = Styled.div`
  background: #ffffff;
  border-radius: 14px;
  padding: 15px;
  position: relative;
  transition: all .4s ease;

  &:hover {
    box-shadow: none;
  }
`;

export default BoxStyle;
export { BoxStyle };
