import React from 'react';
import PropTypes from 'prop-types';

// Component
import { Typography } from '@components';

// Style
import { TitleStyle, IconStyle } from './Styled';

const Title = ({ icon, title, subtitle, as, size, weight }) => (
  <TitleStyle>
    <div>
      {icon ? <IconStyle>{icon}</IconStyle> : null}
      <div>
        <Typography as={as} weight={weight} size={size}>
          {title}
        </Typography>
        <Typography as="span" size="1.4em">
          {subtitle.split('\n').map(str => <p key={str}>{str}</p>)}
        </Typography>
      </div>
    </div>
  </TitleStyle>
);

Title.defaultProps = {
  title: '',
  subtitle: '',
  weight: 'bold',
  size: '18px',
  as: 'h3',
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  weight: PropTypes.string,
  size: PropTypes.string,
  as: PropTypes.oneOf(['h1', 'h2', 'h3']),
  icon: PropTypes.element,
};

export default Title;
export { Title };
