import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { actions as pageSizeActions } from '@store/ducks/pageSize';

// Style
import { MenuDropDown, Checkbox } from '@components';
import { TooltipStyle } from '../Styled';

// Components

const options = [
  {
    label: '25 linhas',
    limit: 25,
    selected: true,
  },
  {
    label: '50 linhas',
    limit: 50,
    selected: false,
  },
  {
    label: '100 linhas',
    limit: 100,
    selected: false,
  },
  {
    label: '500 linhas',
    limit: 500,
    selected: false,
  },
];

const FilterPageSize = () => {
  const dispatch = useDispatch();
  const [optionsList, setOptionsList] = useState(options);
  const [dataLabel, setDataLabel] = useState('Limite de página');
  const setPageSize = (limit) =>
    dispatch(pageSizeActions.setPageSize({ limit }));

  const handleSelect = (label) => {
    const toggleFilter = [...optionsList];
    toggleFilter.forEach((obj) => {
      if (obj.label == label) {
        obj.selected = !obj.selected;
        setPageSize(obj.limit);
      } else obj.selected = false;
    });

    const noneSelected = toggleFilter.find((item) => item.selected === true);
    if (noneSelected) {
      setDataLabel(label);
      setOptionsList(toggleFilter);
    }
  };

  const renderOptions = optionsList.map((option, index) => (
    <li key={index}>
      <Checkbox
        name={option.label}
        label={option.label}
        checked={option.selected}
        onChange={() => handleSelect(option.label)}
      />
    </li>
  ));

  useEffect(() => {
    const selected = optionsList.find((obj) => obj.selected === true);
    setDataLabel(selected.label);
    setPageSize(selected.limit);
  }, []);

  return (
    <>
      <TooltipStyle>
        <MenuDropDown label={dataLabel}>
          <span>Limite de página</span>
          <ul>{renderOptions}</ul>
        </MenuDropDown>
      </TooltipStyle>
    </>
  );
};

FilterPageSize.defaultProps = {};
FilterPageSize.prototypes = {};

export default FilterPageSize;
export { FilterPageSize };
