import Styled from 'styled-components';

const StyleHeader = Styled.header`
  border-bottom: 1px solid #E5E5E5;
  background: #F9F9F9;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  padding: 5px 55px 5px 32px;
  height: 96px;
`;

export default StyleHeader;
export { StyleHeader };
