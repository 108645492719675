
const breakpoints = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '1200px',
  xl: '1440px',
}

export default breakpoints;
export {breakpoints}
