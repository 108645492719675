import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    font-size: 24px;
    padding: 25px 30px 0 30px;
  }
`;

export { Container };
