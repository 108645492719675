/**
 * ---------------------------------------------------
 * DASHBOARD GRAPHICS - STUDY_HOURS
 * ---------------------------------------------------
 */
export const GET_GRAPHIC_STUDY_HOURS_REQUEST = '@dashboard/GET_GRAPHIC_STUDY_HOURS_REQUEST';
export const GET_GRAPHIC_STUDY_HOURS_SUCCESS = '@dashboard/GET_GRAPHIC_STUDY_HOURS_SUCCESS';
export const GET_GRAPHIC_STUDY_HOURS_FAILURE = '@dashboard/GET_GRAPHIC_STUDY_HOURS_FAILURE';
/**
 * ---------------------------------------------------
 * DASHBOARD GRAPHICS - ACCESS_FREQUENCY
 * ---------------------------------------------------
 */
export const GET_GRAPHIC_ACCESS_FREQUENCY_REQUEST = '@dashboard/GET_GRAPHIC_ACCESS_FREQUENCY_REQUEST';
export const GET_GRAPHIC_ACCESS_FREQUENCY_SUCCESS = '@dashboard/GET_GRAPHIC_ACCESS_FREQUENCY_SUCCESS';
export const GET_GRAPHIC_ACCESS_FREQUENCY_FAILURE = '@dashboard/GET_GRAPHIC_ACCESS_FREQUENCY_FAILURE';
/**
 * ---------------------------------------------------
 * DASHBOARD GRAPHICS - COMPLETED_STEPS
 * ---------------------------------------------------
 */
export const GET_GRAPHIC_COMPLETED_STEPS_REQUEST = '@dashboard/GET_GRAPHIC_COMPLETED_STEPS_REQUEST';
export const GET_GRAPHIC_COMPLETED_STEPS_SUCCESS = '@dashboard/GET_GRAPHIC_COMPLETED_STEPS_SUCCESS';
export const GET_GRAPHIC_COMPLETED_STEPS_FAILURE = '@dashboard/GET_GRAPHIC_COMPLETED_STEPS_FAILURE';
/**
 * ---------------------------------------------------
 * DASHBOARD - ACHIEVEMENT
 * ---------------------------------------------------
 */
export const GET_DASH_ACHIEVEMENT_REQUEST = '@dashboard/GET_DASH_ACHIEVEMENT_REQUEST';
export const GET_DASH_ACHIEVEMENT_SUCCESS = '@dashboard/GET_DASH_ACHIEVEMENT_SUCCESS';
export const GET_DASH_ACHIEVEMENT_FAILURE = '@dashboard/GET_DASH_ACHIEVEMENT_FAILURE';
/**
 * ---------------------------------------------------
 * DASHBOARD - STUDY_HOURS
 * ---------------------------------------------------
 */
export const GET_DASH_STUDY_HOURS_REQUEST = '@dashboard/GET_DASH_STUDY_HOURS_REQUEST';
export const GET_DASH_STUDY_HOURS_SUCCESS = '@dashboard/GET_DASH_STUDY_HOURS_SUCCESS';
export const GET_DASH_STUDY_HOURS_FAILURE = '@dashboard/GET_DASH_STUDY_HOURS_FAILURE';
/**
 * ---------------------------------------------------
 * DASHBOARD - MOST_ACCESSED_CONTENT
 * ---------------------------------------------------
 */
export const GET_DASH_MOST_ACCESSED_CONTENT_REQUEST = '@dashboard/GET_DASH_MOST_ACCESSED_CONTENT_REQUEST';
export const GET_DASH_MOST_ACCESSED_CONTENT_SUCCESS = '@dashboard/GET_DASH_MOST_ACCESSED_CONTENT_SUCCESS';
export const GET_DASH_MOST_ACCESSED_CONTENT_FAILURE = '@dashboard/GET_DASH_MOST_ACCESSED_CONTENT_FAILURE';
/**
 * ---------------------------------------------------
 * DASHBOARD - WIDGETS
 * ---------------------------------------------------
 */
export const GET_DASH_WIDGETS_REQUEST = '@dashboard/GET_DASH_WIDGETS_REQUEST';
export const GET_DASH_WIDGETS_SUCCESS = '@dashboard/GET_DASH_WIDGETS_SUCCESS';
export const GET_DASH_WIDGETS_FAILURE = '@dashboard/GET_DASH_WIDGETS_FAILURE';
