import Styled, { css } from 'styled-components';

const buttonField = css`
  > button {
    transition: all 0.2s ease-in-out 0s;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      width: 24px;
      height: 24px;

      > path,
      > rect {
        fill: #ababab;
      }
    }
  }
`;

const FieldInvalid = Styled.dd`
  color: #EB5757;
  margin: ${({ fieldDefault }) => (fieldDefault ? '-5px 15px 15px' : '0 5px 0 0')};
  text-align: left;
  font-size: ${({ invalidStatus }) => (invalidStatus ? '14px' : '0')};
  transition: all 0.9s ease-in-out 0s;
`;

const FieldBox = Styled.label`
  background: #F5F5F5;
  border: 1px solid ;
  border-color: #E3E3E3;
  ${props => props.hasError && css`
    border-color: #EB5757;
  `}
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px 0;
  padding: 12px 20px;
  display: block;
  height: auto;
  transition: all 0.2s ease-in-out 0s;

  > label {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    text-align: left;
    color: #626262;
    ${props => props.hasError && css`
      color: #EB5757;
    `}
    margin-bottom: 5px;
  }

  > div {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    > button {
      background: transparent;
      transition: all 0.2s ease-in-out 0s;
      border: none;
      outline: none;
    }

    > input {
      background: transparent;
      border: none;
      outline: none;
      transition: all 0.2s ease-in-out 0s;
      font-family: 'Avenir';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      color: #000000;
      flex: auto;

      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    ${buttonField}
  }

  &:focus-within {
    background: transparent;
    border: rgb(0, 141, 255) 1px solid;
    box-shadow: 0 0 0 3px rgba(0, 141, 255, 0.3);
  }
`;

const FieldDefault = Styled.div`
  display: block;
  height: auto;
  text-align: left;
  transition: all 0.2s ease-in-out 0s;

  > label {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: block;
    color: #000;
    margin-top:10px;
    display: flex;
    align-items: center;
    justify-content: inherit;
  }

  > div {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    background: ${({ disabled }) => (disabled ? '#e0e0e0' : 'transparent')};
    border: 1px solid;
    border-color: #333333;
    ${props => props.hasError && css`
      border-color: #EB5757;
    `}
    box-sizing: border-box;
    height: 48px;
    border-radius: 7px;
    margin: 5px 0 10px;
    padding: 0 15px;
    transition: all 0.2s ease-in-out 0s;

    > input {
      background: transparent;
      outline: none;
      border: none;
      transition: all 0.2s ease-in-out 0s;
      font-family: 'Avenir';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      height: 100%;
      width: 100%;

      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      &:focus {
        border-color: #00EB84;
      }

      &:disabled {
        background: #e0e0e0;
        color: #707070;
      }
    }

    &:focus-within {
      background: transparent;
      border-color: #00EB84;
    }

    ${buttonField}
  }
`;

export default FieldDefault;
export { FieldBox, FieldDefault, FieldInvalid };
