/* eslint-disable no-alert */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
// eslint-disable-next-line import/no-cycle
import { actions as modalActions } from '@store/ducks/modal';

import * as types from './types';

export function* open() {
  try {
  } catch (error) {}
}

export function* close() {
  try {
  } catch (error) {}
}

export function* watchModal() {
  yield takeEvery(types.OPEN, open);
  yield takeEvery(types.CLOSE, close);
}

export const sagas = [watchModal];
