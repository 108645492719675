import Styled from 'styled-components';

const StyleBrand = Styled.div`
  width: 120px;
  height: auto;
  margin-top: 4px;
`;

export default StyleBrand;
export { StyleBrand };
