import * as types from './types';

/**
 * ---------------------------------------------------
 * PLANS
 * ---------------------------------------------------
 */
export const planRequest = (payload) => ({
  type: types.PLAN_REQUEST,
  payload,
});
export const planSuccess = (payload) => ({
  type: types.PLAN_REQUEST_SUCCESS,
  payload
});
export const planFailure = ({ toast }) => ({
  type: types.PLAN_REQUEST_FAILURE,
  toast,
});

