import React from 'react';
import PropTypes from 'prop-types';
import { ContainerStyle } from './Styled';

const DivContainer = ({ children, fluid, className, style }) => {
  className = `${className} ${fluid ? 'fluid' : ''}`;
  return (
    <ContainerStyle style={style} className={className}>
      {children}
    </ContainerStyle>
  );
};

DivContainer.defaultProps = {
  fluid: false,
  className: '',
  style: {},
  children: <></>,
};

DivContainer.prototypes = {
  fluid: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const Container = React.memo(DivContainer);

export default Container;
export { Container };
