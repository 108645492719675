import * as types from './types';

export const open = (payload) => ({
  type: types.OPEN,
  payload,
});
export const close = (payload) => ({
  type: types.CLOSE,
  payload,
});
