import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { RangeSliderStyle, InputNumber, InputSlider } from './Styled';

const Slider = (props) => {
  let { id, name, min, max, step, value, onChange } = props;

  const checkBeforeOnChange = (e) => {
    e.preventDefault();
    let newValue = Number(e.target.value);
    if (newValue < min || !newValue) newValue = min;
    else if (newValue > max) newValue = max;
    e.target.value = newValue;
    return onChange(e);
  };

  return (
    <RangeSliderStyle>
      <InputSlider
        theme={{ width: value }}
        type="range"
        id={`range-${id || name}`}
        name={`range-${name || id}`}
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={checkBeforeOnChange}
      />
      <InputNumber>
        <input
          type="number"
          id={`number-${id || name}`}
          name={`number-${name || id}`}
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={checkBeforeOnChange}
        />
      </InputNumber>
    </RangeSliderStyle>
  );
};

Slider.defaultProps = {
  name: '',
  id: '',
  min: 1,
  step: 1,
  max: 200,
  value: 100,
  onChange: (e) => e,
};

Slider.prototypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Slider;
export { Slider };
