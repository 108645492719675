import Styled from 'styled-components';

export const TitleModal = Styled.div`
  width:100%;
  padding: 16px;
  margin-bottom: 5px;
  h1{
    font-size:32px;
    line-height:1;
    margin: 0;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EditButton = Styled.button`
  border:1px solid red;
  background: transparent;
  border: none;
  display:flex;
  align-items: end;
  cursor: pointer;
  span{
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #000;
    margin-right:15px
  }
`;

export const Wrapper = Styled.div`
  width:600px;
  padding: 0 48px;
  background-color: #fff;

  @media (max-width: 800px) {
    margin: 16px 0;
    width: 100%;
  }
`;

export const InputRow = Styled.div`
  display: grid;
	grid-template-columns: 1fr 1fr;
  gap: 19px;
`;

export const ButtonWrapper = Styled.div`
  display: flex;
  justify-content: flex-end;
  button{
    width: 215px;
    height: 48px;
    position: relative;
    svg{
      position: absolute;
      right:28px;
      width:24px;
    }
  
  }
 > .secondary {
  button{
      width: 140px;
      min-width: 140px;
      margin-right:16px;
    }
  }

  
`;
