import IconDashboard from '@icons/icon-home-menu.svg';
import IconHandbag from '@icons/icon-home-meu-plano.svg';
import IconUsers from '@icons/icon-home-users.svg';
import IconBenefitiaries from '@icons/friends.svg';
import IconLock from '@icons/lock.svg';
import IconGroups from '@icons/icon-home-social.svg';
import IconSocialImpact from '@icons/trees.svg';

const navigation = [
  {
    href: '/home',
    label: 'Home',
    icon: <IconDashboard />,
  },
  {
    href: '/meu-plano',
    label: 'Meu Plano',
    icon: <IconHandbag />,
  },
  {
    href: '/usuarios',
    label: 'Usuários',
    icon: <IconUsers />,
  },
  {
    href: '/grupos',
    label: 'Grupos',
    icon: <IconGroups />,
  },
  // {
  //   href: '/',
  //   label: 'Beneficiários',
  //   icon: <IconBenefitiaries />,
  // },
  // {
  //   href: '/',
  //   label: 'Impacto Social',
  //   icon: <IconSocialImpact />,
  // },
];

export default navigation;
export { navigation };
