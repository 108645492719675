import React from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import ToastStyles from './Toast.styles';

const ToastWrapper = ({ ...rest }) => {
  return (
    <div className="toast-wrapper">
      <ToastContainer
        pauseOnVisibilityChange
        position="top-right"
        newestOnTop
        autoClose={false}
        hideProgressBar
        closeOnClick
        pauseOnHover
        rtl={false}
        draggable
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </div>
  );
};

const StyledToasts = styled(ToastWrapper)`
  ${ToastStyles}
`;

const Toast = ({ children, type, ...rest }) => {
  /* type can be: error, success, warn and info */
  let config = {};
  if (type === 'error' || type === 'success') {
    config = {
      autoClose: 10860,
    };
  }

  return toast[type](children, { ...config, ...rest });
};

export default Toast;
export { StyledToasts };
