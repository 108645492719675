import { callApiBase } from './base';

const userApi = {
  getFirstAccess: ({ params }) =>
    callApiBase({
      title: 'User - First Access',
      endpoint: '/v1/first-access/user-properties',
      method: 'GET',
      params,
    }),
  sendFirstAccess: ({ data, params }) =>
    callApiBase({
      title: 'User - First Access',
      endpoint: '/v1/first-access',
      method: 'POST',
      data,
      params,
    }),
  sendGetUserByFaToken: ({ token }) =>
    callApiBase({
      title: 'User - Get info by token',
      endpoint: `v1/user`,
      method: 'GET',
      token,
    }),

  /*
  --------------------------------------------------------------
  ADD USER
  --------------------------------------------------------------
  */
  sendGetAllUsers: ({ token, search = null }) =>
    callApiBase({
      title: 'User - Add user',
      endpoint: `/v1/management/users${search ? `?search=${search}` : ''}`,
      method: 'GET',
      token,
    }),
  sendGetUserById: ({ id, token }) =>
    callApiBase({
      title: 'User - Add user',
      endpoint: `/v1/management/users/${id}`,
      method: 'GET',
      token,
    }),
  sendAddUser: ({ data, token }) =>
    callApiBase({
      title: 'User - Add user',
      endpoint: '/v1/management/users',
      method: 'POST',
      token,
      data,
    }),
  sendUpdateUser: ({ data, id, token }) =>
    callApiBase({
      title: 'User - Update User',
      endpoint: `/v1/management/users/${id}`,
      method: 'PATCH',
      data,
      token,
    }),
  sendLogoutUser: ({ token }) =>
    callApiBase({
      title: 'User - Logout User',
      endpoint: `/v1/logout`,
      method: 'POST',
      token,
    }),
  /*
  --------------------------------------------------------------
  TOKEN VALIDATION
  --------------------------------------------------------------
  */
  getTokenValidation: ({ params }) =>
    callApiBase({
      title: 'User - Validation Token',
      endpoint: `/v1/user/check-token`,
      method: 'GET',
      params,
    }),
};

export default userApi;
export { userApi };
