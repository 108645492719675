import nextCookies from 'next-cookies';
import Cookies from 'js-cookie';

const removeToken = () => Cookies.remove('authToken');

const saveToken = ({ type, token }) => {
  if (type === 'client') {
    Cookies.set('authToken', token);
  }
};

const getToken = ({ type, ctx }) => {
  if (type === 'client') {
    return Cookies.get('authToken');
  }

  const { authToken } = nextCookies(ctx);
  return authToken;
};

const validProtectedRoute = ({ type, ctx }) => {
  if (type === 'server') {
    const { authToken } = nextCookies(ctx);
    if (!authToken) {
      ctx.res.writeHead(302, { Location: '/login' });
      ctx.res.end();
    }
  }
};

export {
  validProtectedRoute,
  getToken,
  saveToken,
  removeToken,
}
