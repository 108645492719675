import Styled, { css } from 'styled-components';
import { shade } from 'polished';

const StyleButton = Styled.div`
  margin: 10px 0;
  transition: all 0.2s ease-in-out 0s;

  > a,
  > button {
    font-family: 'Avenir';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: inherit;
    padding: .5rem 15px;
    ${(props) =>
    props.bigger &&
    css`
        padding: 1.15rem 15px;
      `}
    width: ${(props) => props.width};
    min-width: 200px;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    outline: none;
    border-radius: 7px;
    min-height: 42px;

    &.loading {
      cursor: wait;
    }

    & svg {
      margin: ${(props) => props.margin};
    }
  }

  &.black {
    > a,
    > button {
      border-color: #111111;
      background: #111111;
      color: #ffffff;

      &:hover {
        border-color: #000000;
        background: #000000;
      }

      & svg {

        > path,
        > rect {
            stroke: #ffffff;
        }
      }
    }
  }

  &.blue {
    > a,
    > button {
      border-color: #00EB84;
      background: #00EB84;
      color: #ffffff;

      &:hover {
        border-color: ${shade(0.1, '#00EB84')};
        background: ${shade(0.1, '#00EB84')};
      }

      &:disabled {
        opacity: .6;
        background: #00EB846e;
        border-color: #00EB846e;
      }

      &:disabled {
        opacity: .6;
        background: #008dff6e;
        border-color: #008dff6e;
      }

      & svg {

        > path,
        > rect {
            stroke: #ffffff;
        }
      }
    }
  }

  &.outlined {
    > a,
    > button {
      border-color: #DBDBDB;
      background: transparent;
      color: #626262;
      &:hover {
        border-color: #DBDBDB;
        background: #ebebeb;
      }

      & svg {

        > path,
        > rect {
            stroke: #626262;
        }
      }
    }
  }

  &.primary {
    > a,
    > button {
      border-color: ${({ theme }) => theme.color.primary};
      background:  ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.white};

      &:hover {
        border-color:  ${({ theme }) => theme.color.primaryHover};
        background:  ${({ theme }) => theme.color.primaryHover};
      }

      &:disabled {
        opacity: .3;
        cursor: not-allowed;

        &:hover {
          opacity: .3;
          border-color:  ${({ theme }) => theme.color.primary};
          background:  ${({ theme }) => theme.color.primary};
        }
      }

     & svg {

        > path,
        > rect {
            stroke: #FFF !important;
        }
      }
    }
  }

  &.secondary {
    > a,
    > button {
      color: #231F20;

      background: transparent;
      border-color: #CBD4D7;

      &:hover {
        color: #50ffb2;
        background: transparent;
        border-color: #50ffb2;

        & svg {

          > path,
          > rect {
            stroke: #ffffff !important;
          }
        }
      }

      & svg {

        > path,
        > rect {
            stroke: #000000 !important;
        }
      }
    }
  }

  &.icon {
    > a,
    > button {
      color: #333333;

      background: #ffffff;
      border-color: #CBD4D7;

      &:hover {
        color: #ffffff;
        background: #50ffb2;
        border-color: #50ffb2;

        & svg {

          > path,
          > rect {
            stroke: #ffffff !important;
          }
        }
      }

      & svg {

        > path,
        > rect {
            stroke: #666666 !important;
        }
      }
    }
  }

  &.text {
    > a,
    > button {
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      background: none;
      border: none;
      color: #333333;
      font-weight: bold;
      min-width: initial;

      &:hover {
        color: #000000;

        & svg {

          > path,
          > rect {
              stroke: #000000 !important;
          }
        }
      }
    }
  }
`;

export default StyleButton;
export { StyleButton };
