import { put, takeEvery, call } from 'redux-saga/effects';

// Monitoring
import { ClientLogger } from 'src/services/monitoring/clientLogger';

// Actions
import * as actions from './actions';

// WISEUP CORP API
import { dashboardApi } from '@services/api';

// UTILS
import { getToken } from '@utils/auth';

// TYPES
import * as types from './types';

/**
 * ---------------------------------------------------
 * DASHBOARD - STUDY_HOURS
 * ---------------------------------------------------
 */

export function* getGraphicStudyHoursRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(dashboardApi.getGraphicStudyHours, {
      token,
      params: { ...payload.params },
    });
    if (response.status === 200) {
      yield put(
        actions.getGraphicStudyHoursSuccess({
          studyHours: response.data.rows,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        actions.getGraphicStudyHoursFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * DASHBOARD - ACCESS_FREQUENCY
 * ---------------------------------------------------
 */

export function* getGraphicAccessFrequencyRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(dashboardApi.getGraphicAccessFrequency, {
      token,
      params: { ...payload.params },
    });
    if (response.status === 200) {
      yield put(
        actions.getGraphicAccessFrequencySuccess({
          accessFrequency: response.data.rows,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        actions.getGraphicAccessFrequencyFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * DASHBOARD - COMPLETED_STEPS
 * ---------------------------------------------------
 */

export function* getGraphicCompletedStepsRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(dashboardApi.getGraphicCompletedSteps, {
      token,
      params: { ...payload.params },
    });
    if (response.status === 200) {
      yield put(
        actions.getGraphicCompletedStepsSuccess({
          completedSteps: response.data.rows,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        actions.getGraphicCompletedStepsFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * DASHBOARD - ACHIEVEMENT
 * ---------------------------------------------------
 */

export function* getDashAchievementRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(dashboardApi.getDashAchievement, {
      token,
    });
    if (response.status === 200) {
      yield put(
        actions.getDashAchievementSuccess({
          achievement: response.data.rows,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        actions.getDashAchievementFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * DASHBOARD - STUDY_HOURS
 * ---------------------------------------------------
 */

export function* getDashStudyHoursRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(dashboardApi.getDashStudyHours, {
      token,
    });
    if (response.status === 200) {
      yield put(
        actions.getDashStudyHoursSuccess({
          studyHours: response.data.rows,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        actions.getDashStudyHoursFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * DASHBOARD - MOST_ACCESSED_CONTENT
 * ---------------------------------------------------
 */

export function* getDashMostAccessedContentRequest({ payload }) {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(dashboardApi.getDashMostAccessedContent, {
      token,
    });
    if (response.status === 200) {
      yield put(
        actions.getDashMostAccessedContentSuccess({
          mostAccessedContent: response.data.rows,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        actions.getDashMostAccessedContentFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * DASHBOARD - WIDGETS
 * ---------------------------------------------------
 */

export function* getDashWidgetsRequest() {
  try {
    const token = getToken({ type: 'client' });
    const response = yield call(dashboardApi.getDashWidgets, {
      token,
    });
    if (response.status === 200) {
      yield put(
        actions.getDashWidgetsSuccess({
          widgets: response.data,
        })
      );
    }
  } catch (error) {
    ClientLogger.captureException(error);
    if (error.response) {
      yield put(
        actions.getDashWidgetsFailure({
          toast: {
            type: 'error',
            message: error.response.data.errors[0].message,
          },
        })
      );
    }
  }
}

export function* watchDashboard() {
  // GET_GRAPHIC_STUDY_HOURS_REQUEST
  yield takeEvery(
    types.GET_GRAPHIC_STUDY_HOURS_REQUEST,
    getGraphicStudyHoursRequest
  );

  // GET_GRAPHIC_ACCESS_FREQUENCY
  yield takeEvery(
    types.GET_GRAPHIC_ACCESS_FREQUENCY_REQUEST,
    getGraphicAccessFrequencyRequest
  );

  // GET_GRAPHIC_COMPLETED_STEPS_REQUEST
  yield takeEvery(
    types.GET_GRAPHIC_COMPLETED_STEPS_REQUEST,
    getGraphicCompletedStepsRequest
  );

  // GET_DASH_ACHIEVEMENT
  yield takeEvery(
    types.GET_DASH_ACHIEVEMENT_REQUEST,
    getDashAchievementRequest
  );

  // GET_DASH_STUDY_HOURS
  yield takeEvery(types.GET_DASH_STUDY_HOURS_REQUEST, getDashStudyHoursRequest);

  // GET_DASH_MOST_ACCESSED_CONTENT
  yield takeEvery(
    types.GET_DASH_MOST_ACCESSED_CONTENT_REQUEST,
    getDashMostAccessedContentRequest
  );

  // GET_DASH_WIDGETS
  yield takeEvery(types.GET_DASH_WIDGETS_REQUEST, getDashWidgetsRequest);
}

export const sagas = [watchDashboard];
