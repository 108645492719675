import { callApiBase } from './base';

const groupApi = {
	sendGetAllGroup: ({ token }) =>
		callApiBase({
			title: 'Group - Get all groups',
			endpoint: '/v1/user/group',
			method: 'GET',
			token,
		}),
	sendAddGroup: ({ token, data }) =>
		callApiBase({
			title: 'Groups - ADD',
			endpoint: '/v1/user/group',
			method: 'PUT',
			data,
			token,
		}),
};

export default groupApi;
export { groupApi };
