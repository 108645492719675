import React from 'react';
import PropTypes from 'prop-types';

// Style
import { ProgressBarStyle } from './Styled';

const ProgressBar = ({ progress, height }) => {
  return (
    <ProgressBarStyle theme={{ progress, height }}>
      <div>
        <div />
      </div>
    </ProgressBarStyle>
  );
};

ProgressBar.defaultProps = {
  height: '8px',
  progress: 0,
};

ProgressBar.propTypes = {
  height: PropTypes.string,
  progress: PropTypes.number,
};

export default ProgressBar;
export { ProgressBar };
