import Styled from 'styled-components';

const TooltipDateStyle = Styled.div``;

const TooltipStyle = Styled.div`
  overflow: visible;

  & ul,
  & ol {
    margin: 15px 0  0 0;


    > li {
      display: flex;
      justify-content: center;
      align-itens: center;
      cursor: default;

      & label {
        cursor: pointer;
      }
    } 
  } 
`;

export default TooltipStyle;
export { TooltipStyle, TooltipDateStyle };
