import React from 'react';
import { useRouter } from 'next/router';
import { Button, Typography } from '@components';
import { Wrapper, SubTitle, ButtonContainer } from './styles';

const LicenseLimit = () => {
  const router = useRouter();

  return (
    <Wrapper>
      <Typography
        align="center"
        color="#231F20"
        as="h1"
        dsize="32px"
        className="title"
        weight="600"
        dheight="37px"
      >
        Você atingiu o limite de licenças disponíveis
      </Typography>

      <SubTitle>
        Para adicionar mais usuários você pode inativar algum usuário ou
        adquirir mais licenças.
      </SubTitle>

      <ButtonContainer>
        <Button
          onClick={() => router.push('/meu-plano')}
          block
          variant="primary"
        >
          Adquirir Licenças
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default LicenseLimit;
