const inputMask = (mask) => {
  switch (mask) {
    case 'phone':
      return [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    default:
      return false;
  }
};
export { inputMask };
export default inputMask;
