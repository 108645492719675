import { format } from 'date-fns';

const getPreviousDate = (previousDay) => {
  const now = new Date();
  const unixDate = now.setDate(now.getDate() - previousDay);
  const date = getDateNowServer(unixDate);
  return date;
};

const getDateInFullShort = (date) => {
  const settings = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timezone: 'America/Sao_Paulo',
  };
  const objDate = new Date(`"${date}"`);
  const dateFormat = objDate.toLocaleDateString('pt-BR', settings);
  return dateFormat;
  // EXAMPLE: 6 de dez. de 2020
};

const getDateInFullLong = (date) => {
  const settings = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timezone: 'America/Sao_Paulo',
  };
  const objDate = new Date(`"${date}"`);
  const dateFormat = objDate.toLocaleDateString('pt-BR', settings);
  return dateFormat;
  // EXAMPLE: 6 de dezembro de 2020
};

const getDateMonthYear = (date) => {
  const settings = { month: 'long' };

  const monthName = (month) =>
    new Date(`01/${month}/2020`).toLocaleDateString('pt-BR', settings);

  const objDate = new Date(`"${date}"`);
  const month = objDate.getMonth() + 1;
  const fullYear = objDate.getFullYear();
  return monthName(month) + ' ' + fullYear;
  // EXAMPLE: dezembro 2020
};

const getDateNowServer = (date) => {
  const objDate = date ? new Date(date) : new Date();
  let month = `${objDate.getMonth() + 1}`;
  let day = `${objDate.getDate()}`;
  const year = objDate.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join('-');
  // EXAMPLE: 2020-12-22
};

const getAllMonths = () => {
  const settings = { month: 'long' };
  const allMonths = [];

  for (let i = 1; i <= 12; i++) {
    const month = new Date(`${i}/01/2020`).toLocaleDateString(
      'pt-BR',
      settings
    );
    allMonths.push(month);
  }

  return allMonths;
  // EXAMPLE: ['jan.', 'fev.', 'mar.', ..., 'dez'];
};

export const formatDateTime = (date) =>
  format(new Date(date), 'dd/MM/yyyy - HH:mm');

export {
  getAllMonths,
  getPreviousDate,
  getDateNowServer,
  getDateInFullShort,
  getDateInFullLong,
  getDateMonthYear,
};
