import React, { useState, useEffect } from 'react';

// UTIL
import { getAllMonths } from '@utils';

// STYLE
import { TooltipStyle, TooltipDateStyle } from '../Styled';

// COMPONENTS
import { MenuDropDown, Checkbox } from '@components';

// Icons component
import ArrowRight from '@icons/chevron-right.svg';

const options = [
  {
    label: '7 dias',
    previousDay: 7,
    selected: true,
  },
  {
    label: '15 dias',
    previousDay: 15,
    selected: false,
  },
  {
    label: '30 dias',
    previousDay: 30,
    selected: false,
  },
];

const FilterDate = (props) => {
  const { getFilterDate, filter } = props;
  const [optionsList, setOptionsList] = useState(options);
  const getSelected = optionsList.find(obj => obj.selected);
  const [dataLabel, setDataLabel] = useState(getSelected?.label || 'Filtro de data');

  const handleCheckbox = (label) => {
    const toggleFilter = [...optionsList];
    toggleFilter.forEach((obj) => {
      obj.label === label ? obj.selected = !obj.selected : obj.selected = false
    });
    const noneSelect = toggleFilter.find(obj => obj.selected);
    if (noneSelect) {
    setDataLabel(label);
    setOptionsList(toggleFilter);
    }
  };

  const checkboxList = optionsList.map((option, index) => (
    <li key={index}>
      <Checkbox
        name={filter + option.label}
        label={option.label}
        checked={option.selected}
        onChange={() => handleCheckbox(option.label)}
      />
    </li>
  ));


  useEffect(() => {
    if (getFilterDate) return getFilterDate(getSelected.previousDay);
  }, [optionsList]);

  return (
    <TooltipStyle>
      <TooltipDateStyle>
        <MenuDropDown label={dataLabel} overflow='visible'>
          <span>Escolha um período</span>
          <ul>
            {checkboxList}
          </ul>
        </MenuDropDown>
      </TooltipDateStyle>
    </TooltipStyle>
  );
};

FilterDate.defaultProps = {};
FilterDate.prototypes = {};

export default FilterDate;
export { FilterDate };
