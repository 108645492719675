// TYPES
import * as types from './types';

/**
 * ---------------------------------------------------
 * DASHBOARD - GRAPHIC_STUDY_HOURS
 * ---------------------------------------------------
 */

export const getGraphicStudyHoursRequest = (payload) => ({
  type: types.GET_GRAPHIC_STUDY_HOURS_REQUEST,
  payload,
});
export const getGraphicStudyHoursSuccess = (payload) => ({
  type: types.GET_GRAPHIC_STUDY_HOURS_SUCCESS,
  payload,
});
export const getGraphicStudyHoursFailure = ({ toast }) => ({
  type: types.GET_GRAPHIC_STUDY_HOURS_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * DASHBOARD - GRAPHIC_ACCESS_FREQUENCY
 * ---------------------------------------------------
 */

export const getGraphicAccessFrequencyRequest = (payload) => ({
  type: types.GET_GRAPHIC_ACCESS_FREQUENCY_REQUEST,
  payload,
});
export const getGraphicAccessFrequencySuccess = (payload) => ({
  type: types.GET_GRAPHIC_ACCESS_FREQUENCY_SUCCESS,
  payload,
});
export const getGraphicAccessFrequencyFailure = ({ toast }) => ({
  type: types.GET_GRAPHIC_ACCESS_FREQUENCY_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * DASHBOARD - GRAPHIC_COMPLETED_STEPS
 * ---------------------------------------------------
 */

export const getGraphicCompletedStepsRequest = (payload) => ({
  type: types.GET_GRAPHIC_COMPLETED_STEPS_REQUEST,
  payload,
});
export const getGraphicCompletedStepsSuccess = (payload) => ({
  type: types.GET_GRAPHIC_COMPLETED_STEPS_SUCCESS,
  payload,
});
export const getGraphicCompletedStepsFailure = ({ toast }) => ({
  type: types.GET_GRAPHIC_COMPLETED_STEPS_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * DASHBOARD - ACHIEVEMENT
 * ---------------------------------------------------
 */

export const getDashAchievementRequest = (payload) => ({
  type: types.GET_DASH_ACHIEVEMENT_REQUEST,
  payload,
});
export const getDashAchievementSuccess = (payload) => ({
  type: types.GET_DASH_ACHIEVEMENT_SUCCESS,
  payload,
});
export const getDashAchievementFailure = ({ toast }) => ({
  type: types.GET_DASH_ACHIEVEMENT_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * DASHBOARD - STUDY_HOURS
 * ---------------------------------------------------
 */

export const getDashStudyHoursRequest = (payload) => ({
  type: types.GET_DASH_STUDY_HOURS_REQUEST,
  payload,
});
export const getDashStudyHoursSuccess = (payload) => ({
  type: types.GET_DASH_STUDY_HOURS_SUCCESS,
  payload,
});
export const getDashStudyHoursFailure = ({ toast }) => ({
  type: types.GET_DASH_STUDY_HOURS_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * DASHBOARD - MOST_ACCESSED_CONTENT
 * ---------------------------------------------------
 */

export const getDashMostAccessedContentRequest = (payload) => ({
  type: types.GET_DASH_MOST_ACCESSED_CONTENT_REQUEST,
  payload,
});
export const getDashMostAccessedContentSuccess = (payload) => ({
  type: types.GET_DASH_MOST_ACCESSED_CONTENT_SUCCESS,
  payload,
});
export const getDashMostAccessedContentFailure = ({ toast }) => ({
  type: types.GET_DASH_MOST_ACCESSED_CONTENT_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * DASHBOARD - WIDGETS
 * ---------------------------------------------------
 */

export const getDashWidgetsRequest = (payload) => ({
  type: types.GET_DASH_WIDGETS_REQUEST,
  payload,
});
export const getDashWidgetsSuccess = (payload) => ({
  type: types.GET_DASH_WIDGETS_SUCCESS,
  payload,
});
export const getDashWidgetsFailure = ({ toast }) => ({
  type: types.GET_DASH_WIDGETS_FAILURE,
  toast,
});
