import Styled from 'styled-components';

const StyleTopButton = Styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px

`

export default StyleTopButton;
export { StyleTopButton };