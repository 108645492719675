import React from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';
import { StyleButton } from './Styled';

const Button = (props) => {
  const {
    variant,
    children,
    onClick,
    href,
    leftIcon,
    rightIcon,
    hrefExternal,
    type,
    disabled,
    loading,
    block,
    bigger,
  } = props;

  const child = loading ? 'aguarde...' : children;
  const width = block ? '100%' : 'auto';

  const content = (
    <>
      {leftIcon || null}
      {child}
      {rightIcon || null}
    </>
  );

  const margin = leftIcon ? '0 10px 0 0' : rightIcon ? '0 0 0 10px' : '0';

  if (hrefExternal) return <a href={hrefExternal}>{content}</a>;

  if (href)
    return (
      <StyleButton width={width} margin={margin} className={variant}>
        <Link href={href}>
          <a>{content}</a>
        </Link>
      </StyleButton>
    );

  return (
    <StyleButton
      width={width}
      margin={margin}
      className={variant}
      bigger={bigger}
    >
      <button
        className={loading ? 'loading' : ''}
        disabled={disabled || loading}
        onClick={onClick}
        type={type}
      >
        {content}
      </button>
    </StyleButton>
  );
};

Button.defaultProps = {
  hrefExternal: '',
  variant: 'primary',
  rightIcon: false,
  leftIcon: false,
  onClick: () => null,
  disabled: false,
  loading: false,
  block: false,
  type: 'button',
  href: '',
  children: '',
  bigger: false,
};

Button.propTypes = {
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  leftIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  rightIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  href: PropTypes.string,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  bigger: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'black',
    'text',
    'icon',
    'blue',
    'outlined'
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hrefExternal: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
};

export default Button;
export { Button };
