import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { actions as modalActions } from '@store/ducks/modal';

// Import Templates
import {
  AddUser,
  EditUser,
  AddGroup,
  InactivateUser,
  InactivateUserSuccess,
  LicenseLimit,
  ResetPasswordEmailSendFeedback,
  PasswordResetedFeedback,
} from './templates';

// Styles
import { StyleModal, Close } from './Styled';

const Modal = () => {
  const dispatch = useDispatch();
  const [smallerBox, setSmallerBox] = useState(false);
  const closeModal = () => dispatch(modalActions.close());
  const { visible, type, props } = useSelector((state) => state.modal);

  const GetTemplate = React.useMemo(() => {
    switch (type) {
      case 'ADD_USER':
        return <AddUser />;
      case 'EDIT_USER':
        return <EditUser props={props} />;
      case 'ADD_GROUP':
        return <AddGroup />;
      case 'INACTIVATE_USER':
        return <InactivateUser props={props} />;
      case 'INACTIVATE_USER_SUCCESS':
        return <InactivateUserSuccess props={props} />;
      case 'LICENSE_LIMIT':
        return <LicenseLimit />;
      case 'RESET_PASSWORD_SUCCESS':
        setSmallerBox(true);
        return <PasswordResetedFeedback />;
      case 'RESET_PASSWORD_FEEDBACK':
        setSmallerBox(true);
        return <ResetPasswordEmailSendFeedback />
      default:
        return <></>;
    }
  }, [props, type]);

  return (
    <StyleModal className={visible ? 'show' : ''}>
      <div>
        <div className={smallerBox ? "modal-content smaller-box" : "modal-content"}>
          <Close>
            <button type="button" onClick={closeModal}>
              <img src="/assets/icons/x.svg" alt="fechar" />
            </button>
          </Close>
          <div className="modal-body">{GetTemplate}</div>
        </div>
      </div>
    </StyleModal>
  );
};

Modal.initialProps = {};

export default Modal;
export { Modal };
