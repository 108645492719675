import { takeEvery } from 'redux-saga/effects';

import * as types from './types';

export function* setPageSize() {
  try {
  } catch (error) {}
}

export function* getPageSize() {
  try {
  } catch (error) {}
}

export function* watchPageSize() {
  yield takeEvery(types.SET_PAGE_SIZE, setPageSize);
  yield takeEvery(types.GET_PAGE_SIZE, getPageSize);
}

export const sagas = [watchPageSize];
