import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@components';

// Component Icon
import UpArrow from '@icons/up-arrow.svg';

import { StyleTopButton } from './Styled'

const TopButton = (props) => {
    const {
        label,
        leftIcon,
        rightIcon,
        variant
    } = props;

    const toTheTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <StyleTopButton>
            <Button
                onClick={toTheTop}
                variant={variant}
                rightIcon={rightIcon ? <UpArrow /> : false}
                leftIcon={leftIcon ? <UpArrow /> : false}>
                {label}
            </Button>
        </StyleTopButton>
    );
};

Button.defaultProps = {
    hrefExternal: '',
    variant: 'primary',
    rightIcon: false,
    leftIcon: false,
    onClick: () => null,
    disabled: false,
    loading: false,
    block: false,
    type: 'button',
    href: '',
    children: '',
    bigger: false,
};

Button.propTypes = {
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
    leftIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    rightIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    label: PropTypes.string,
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'black',
        'text',
        'icon',
        'blue',
        'outlined'
    ]),
    onClick: PropTypes.func,
};

export default TopButton;
export { TopButton };
