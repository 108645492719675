import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { TablePaginationTrStyle } from './Styled'

// Actions
import { actions as pageSizeActions } from '@store/ducks/pageSize';

const TablePagination = (props) => {
  const { rows, loading, grid } = props;
  const limitPagination = 25;
  const { limitPage } = useSelector((state) => state.pageSize);
  const [limit, setLimit] = useState(limitPage || limitPagination);
  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(limit);
  const { page } = useRouter().query;
  const pageCurrent = parseInt(page || 1);
  const dispatch = useDispatch();
  const router = useRouter();

  const hasTrophy = (row) => {
    return Number(row?.steps?.split(' ')[0]) > 0 ||
      Number(row?.time?.split(':')[0]) > 0 ||
      Number(row?.time?.split(':')[1]) > 0
  }

  const buildCols = (obj) => {
    const arrCols = [];
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const renderTable = grid ? (
          <div key={prop}>{obj[prop]}</div>
        ) : (
            <td key={prop}>{obj[prop]}</td>
          );
        arrCols.push(renderTable);
      }
    }
    return arrCols;
  };

  const buildRows = () => {
    const arrRows = [];
    for (let i = startRange; i < endRange; i++) {
      // rows[i].steps = '0 Módulos'; //Descomente para testar steps com 0 módulos;
      // rows[i].time = '0:0'; // Descomente para testar steps com 0 módulos;
      const cols = buildCols(rows[i]);
      const renderTable = grid ? cols : <TablePaginationTrStyle
        checkPageOne={router.query.page}
        hasTrophy={hasTrophy(rows[i])}
        key={i}>{cols}
      </TablePaginationTrStyle>;
      arrRows.push(renderTable);
    }
    return arrRows;
  };

  useEffect(() => {
    const totalItems = rows.length;
    const start = (pageCurrent - 1) * limit;
    const isOver = start + limit > totalItems;
    const end = isOver ? totalItems : start + limit;
    setStartRange(start);
    setEndRange(end);
  }, [pageCurrent, rows, limit]);

  useEffect(() => {
    setLimit(limitPage);
  }, [limitPage]);

  useEffect(() => {
    dispatch(pageSizeActions.getPageSize());
  }, []);

  if (!rows.length) return <></>;

  return buildRows();
};

TablePagination.defaultProps = {
  rows: [],
  loading: false,
  grid: false,
};

TablePagination.propTypes = {
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  grid: PropTypes.bool,
};

export default TablePagination;
export { TablePagination };
