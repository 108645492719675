import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// Style
import { MenuDropDown, Checkbox } from '@components';
import { TooltipStyle } from '../Styled';

// Components

const options = [
  {
    label: 'Ativos',
    selected: true,
  },
  {
    label: 'Inativos',
    selected: true,
  },
];

const FilterStatus = ({ getFilterStatus }) => {
  const [optionsList, setOptionsList] = useState(options);
  const [fullCheck, setFullCheck] = useState(true);
  const router = useRouter();

  const handleFullCheck = () => {
    const listOptions = [...optionsList];
    listOptions.forEach((obj) => (obj.selected = !fullCheck));
    setOptionsList(listOptions);
    setFullCheck(!fullCheck);
  };

  const handleSelect = (label) => {
    const toggleFilter = [...optionsList];
    toggleFilter.forEach((obj) => {
      if (obj.label === label) obj.selected = !obj.selected;
      if (!obj.selected) setFullCheck(false);
    });

    const isFullCheck = toggleFilter.filter((status) => status.selected);
    const { pathname, query } = router;
    router.push({ pathname, query: { ...query, page: 1 } });

    setOptionsList(toggleFilter);
    if (isFullCheck.length > 1) return handleFullCheck();
  };

  const renderFullCheck = (
    <li>
      <Checkbox
        name="fullCheck-status"
        label="Todos"
        checked={fullCheck}
        onChange={handleFullCheck}
      />
    </li>
  );

  const renderOptions = optionsList.map((option, index) => (
    <li key={index}>
      <Checkbox
        name={option.label}
        label={option.label}
        checked={option.selected}
        onChange={() => handleSelect(option.label)}
      />
    </li>
  ));

  useEffect(() => {
    if (getFilterStatus) {
      const activeStatus = optionsList[0].selected;
      const inactiveStatus = optionsList[1].selected;
      const responseStatus = {
        active: activeStatus,
        inactive: inactiveStatus,
        all: fullCheck,
        none: Boolean(!activeStatus && !inactiveStatus),
      };
      getFilterStatus(responseStatus);
    }
  }, [optionsList]);

  return (
    <>
      <TooltipStyle>
        <MenuDropDown label="Filtro de Status">
          <span>Especifique um status</span>
          <ul>
            {renderFullCheck}
            {renderOptions}
          </ul>
        </MenuDropDown>
      </TooltipStyle>
    </>
  );
};

FilterStatus.defaultProps = {};
FilterStatus.prototypes = {
  getFilterStatus: PropTypes.func,
};

export default FilterStatus;
export { FilterStatus };
