const setSingularPlural = ({count = 0, word = ''}) => {
  count = parseInt(count);
  word = word.toString();
  const isPlural = count > 1;
  const output = `${count} ${word}${isPlural ? 's' : ''}`;
  return output;
};

export default setSingularPlural;
export { setSingularPlural };
