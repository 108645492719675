import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';

import { actions as userActions } from '@store/ducks/user';

// UTILS
import { removeToken } from '@utils';

// Components
import { MenuDropDownUserHeader } from '@components';

// Components SVG
import LogoutIcon from '@icons/logout-2.svg';

const UserMenu = () => {
  const { userData } = useSelector((state) => state.user);
  const [userName, setUserName] = useState('');
  const router = useRouter();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(userActions.sendLogoutUserRequest());
    removeToken();
    router.push('/login');
  };

  useEffect(() => {
    if (userData?.name && userData?.surname) {
      setUserName(`${userData?.name} ${userData?.surname}`);
      return;
    }
    const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    setUserName(`${loggedUser?.name} ${loggedUser?.surname}`);
  }, [userData]);

  const MenuOptions = (
    <ul>
      <li onClick={logOut}>
        <p>Sair</p>
        <LogoutIcon />
      </li>

      <div>
        <li>
          <a
            href={
              process.env.APPLICATION_ENV === 'production'
                ? 'https://wiseupcorp.com/termos/'
                : 'https://test.wiseupcorp.com/termos/'
            }
            target="_blank"
          >
            Termos de uso
          </a>
        </li>
        <li>
          <a
            href={
              process.env.APPLICATION_ENV === 'production'
                ? 'https://wiseupcorp.com/politica/'
                : 'https://test.wiseupcorp.com/politica/'
            }
            target="_blank"
          >
            Política de Privacidade
          </a>
        </li>
      </div>
    </ul>
  );

  return (
    <MenuDropDownUserHeader label={userName || ''}>
      {MenuOptions}
    </MenuDropDownUserHeader>
  );
};

export default UserMenu;
export { UserMenu };
