import React, { useState, useEffect, useCallback } from 'react';
import { validateEmail, validateNameComplete } from '@utils/inputValid';

// LIBS
import { arrGroups, getGroups } from '@utils';
import { useSelector, useDispatch } from 'react-redux';

import {
  InputBase as Input,
  Button,
  Typography,
  SelectBase,
} from '@components';

// Form & Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';
import { actions as modalActions } from '@store/ducks/modal';
import { actions as groupActions } from '@store/ducks/group';

// Styles
import ArrowRight from '@icons/arrow-right.svg';
import { TitleModal, Wrapper, InputRow, ButtonWrapper } from './styles';

const ModalAddUser = () => {
  const dispatch = useDispatch();
  const [selectGroup, setSelectGroup] = useState([]);
  const { loading, errorMessage } = useSelector((state) => state.user);
  const { groups } = useSelector((state) => state.group);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      name: '',
      employee_registry: '',
      phone: '',
      position: '',
      group: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .test({
          message: 'Revise seu nome e sobrenome',
          test: (fullName) => validateNameComplete(fullName),
        })
        .min(2, 'No mínimo 2 caracteres.')
        .max(85, 'No máximo 85 caracteres.')
        .required('O nome e sobrenome é obrigatório.'),

      email: Yup.string()
        .min(7, 'Um email precisa de no mínimo 7 caracteres')
        .max(64, 'O Endereço de email esta muito grande')
        .test({
          message: 'O email é inválido',
          test: (email) => validateEmail(email),
        })
        .lowercase()
        .required('O email é obrigatório'),

      employee_registry: Yup.string().max(15, 'No máximo 15 caracteres.'),
      position: Yup.string().max(85, 'No máximo 85 caracteres.'),
      phone: Yup.string()
        .required('O celular é obrigatório')
        .min(15, 'Digite o número completo.'),
      group: Yup.string().required('O grupo é obrigatório.'),
    }),

    onSubmit: async (values) => {
      const { email, name, employee_registry, position, phone, group } = values;
      dispatch(
        userActions.sendAddUserRequest({
          data: {
            email: email.toLowerCase(),
            name,
            employee_registry,
            phone,
            position,
            group,
          },
        })
      );
    },
  });

  useEffect(() => {
    if (groups.length) {
      setSelectGroup(getGroups(groups));
    }
  }, [groups]);

  useEffect(() => {
    dispatch(groupActions.getAllGroupsRequest());
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch(modalActions.close({ type: 'ADD_USER', props: 'CLOSE_ADD_USER' }));
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage) {
      formik.setErrors({
        email: 'Este email encontra-se ativo na base do Wise Up Online.',
      });
    }
  }, [errorMessage]);

  return (
    <>
      <TitleModal>
        <Typography as="h1" weight="bold" size="32px" className="title">
          Novo Usuário
        </Typography>
      </TitleModal>

      <form onSubmit={formik.handleSubmit}>
        <Wrapper>
          <Input
            name="name"
            tooltipMessage="Este campo não pode ser editado depois de cadastrar o usuário. Caso necessite de ajustes neste campo, entre em contato com nosso suporte."
            value={formik.values.name}
            placeholder="Nome completo do colaborador"
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
            label="Nome Completo"
            status={
              formik.errors.name && formik.touched.name
                ? formik.errors.name
                : null
            }
          />

          <InputRow>
            <Input
              name="email"
              tooltipMessage={
                errorMessage ||
                'Este campo não pode ser editado depois que o usuário fez o primeiro acesso no Wise Up Online. Caso necessite de ajustes neste campo depois do primeiro acesso do usuário, entre em contato com nosso suporte.'
              }
              label="E-mail Corporativo"
              placeholder="nome.sobrenome@empresa.com"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              status={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null
              }
            />

            <Input
              name="phone"
              tooltipMessage="Este campo não pode ser editado depois de cadastrar o usuário. Caso necessite de ajustes neste campo, entre em contato com nosso suporte."
              mask="phone"
              label="Celular"
              placeholder="(11) 9 87654-3210"
              value={formik.values.phone}
              onChange={(e) => {
                formik.setFieldValue('phone', e.target.value);
              }}
              status={
                formik.errors.phone && formik.touched.phone
                  ? formik.errors.phone
                  : null
              }
            />
          </InputRow>

          <InputRow>
            <Input
              name="employee_registry"
              label="Matrícula"
              placeholder="1234-5678"
              value={formik.values.employee_registry}
              onChange={(e) =>
                formik.setFieldValue('employee_registry', e.target.value)
              }
              status={
                formik.errors.employee_registry &&
                formik.touched.employee_registry
                  ? formik.errors.employee_registry
                  : null
              }
            />

            <Input
              name="position"
              label="Função"
              placeholder="Gerente comercial"
              value={formik.values.position}
              onChange={(e) => formik.setFieldValue('position', e.target.value)}
              status={
                formik.errors.position && formik.touched.position
                  ? formik.errors.position
                  : null
              }
            />
          </InputRow>

          <SelectBase
            name="group"
            label="Grupo"
            placeholder="Business"
            value={formik.values.group}
            onChange={(e) => formik.setFieldValue('group', e.target.value)}
            options={selectGroup}
            refProp="title"
            variant="inside-label"
            status={
              formik.errors.group && formik.touched.group
                ? formik.errors.group
                : null
            }
          />

          <ButtonWrapper>
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancelar
            </Button>

            <Button
              type="submit"
              variant="primary"
              loading={loading}
              rightIcon={<ArrowRight />}
              block
            >
              Salvar
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </form>
    </>
  );
};

export default ModalAddUser;
