import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
// eslint-disable-next-line import/no-cycle
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  loading: null,
  groups: [],
  initiatedGetAllGroupsRequest: false,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.group,
      };
    /**
     * ---------------------------------------------------
     * CHANGE GROUP
     * ---------------------------------------------------
     */
    case types.CHANGE_GROUP:
      return {
        ...state,
        groups: [...action.payload],
      };
    /**
     * ---------------------------------------------------
     * ADD GROUP
     * ---------------------------------------------------
     */
    case types.SEND_ADD_GROUP_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case types.SEND_ADD_GROUP_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        ...action.payload,
      };
    case types.SEND_ADD_GROUP_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    /**
     * ---------------------------------------------------
     * GET ALL GROUPS
     * ---------------------------------------------------
     */
    case types.GET_ALL_GROUPS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
        ...action.payload,
      };
    case types.GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        initiatedGetAllGroupsRequest: true,
        ...action.payload,
      };
    case types.GET_ALL_GROUPS_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
        ...action.payload,
      };

    case types.CHANGE_GROUP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { actions, sagas };
