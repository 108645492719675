import React from 'react';
import PropTypes from 'prop-types';

import IconSearch from '@icons/search.svg';
import { Button } from '@components';
import { SearchStyle, ButtonContainer } from './Styled';

const Search = ({
  onChange,
  onSubmit,
  value,
  placeholder,
  withSubmit = false,
}) => {
  return (
    <SearchStyle onSubmit={onSubmit}>
      {!withSubmit && <IconSearch />}
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {withSubmit && (
        <ButtonContainer>
          <Button variant="icon" onClick={onSubmit} block>
            <IconSearch />
          </Button>
        </ButtonContainer>
      )}
    </SearchStyle>
  );
};

Search.defaultProps = {
  onChange: () => null,
  onSubmit: () => null,
  value: '',
  placeholder: '',
};

Search.prototypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default Search;
export { Search };
