import React from 'react';
import PropTypes from 'prop-types';

// Style
import BoxStyle from './Styled';

const Box = ({ children, border }) => {
  return <BoxStyle>{children}</BoxStyle>;
};

Box.defaultProps = {
  children: <></>,
};

Box.prototypes = {
  children: PropTypes.element.isRequired,
};

export default Box;
export { Box };
